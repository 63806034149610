import React from "react";
import { Helmet } from "react-helmet";
import "../pages/home.scss";
import Header from "../components/Header";
import Footer from "../components/footer";
import { CvPageHeaderData } from "../data/CvPageHeaderData";
import Skills from "../components/skills/Skills";
import ProfessionalExp from "../components/experience/ProfessionalExp";
import Education from "../components/education/Education";
import HeroCV from "../components/about-me/HeroCV";
import PreviousHighlights from "../components/highlights/PreviousHighlights";

const cv = () => {
  const headerData = CvPageHeaderData;
  return (
    <div id={"#cv"}>
      <Helmet>
        <title>Ashmi Banerjee</title>
      </Helmet>
      <Header items={headerData} />
      <section className="Home centeredContainer">
        <div className="tile is-ancestor">
          <div className="tile is-parent is-vertical blocks">
            <div className="tile is-child box">
              <HeroCV />
            </div>
            <div className="tile is-child box">
              <ProfessionalExp />
            </div>
            <div className="tile is-child box">
              <Education />
            </div>
            <div className="tile is-child box">
              <Skills />
            </div>
            {/*<div className="tile is-child box">*/}
            {/*  <Miscellaneous />*/}
            {/*</div>*/}
            <div className="tile is-child box">
              <p className="title" style={{ color: "#0a478a" }}>
                Previously
              </p>
              <PreviousHighlights />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default cv;
