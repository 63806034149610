import React from "react";
import { Helmet } from "react-helmet";
import "../pages/home.scss";
import Header from "../components/Header";
import Footer from "../components/footer";
import { HeaderData } from "../data/HeaderData";
import AboutMe from "../components/about-me/AboutMe";
import Activities from "../components/highlights/Activities";
import Highlights from "../components/highlights/Highlights";
import HeroCV from "../components/about-me/HeroCV";
import Publications from "../components/publications/Publications";
import Miscellaneous from "../components/miscellaneous/Miscellaneous";

const Home = () => {
  const headerData = HeaderData;
  return (
    <div id={"home"}>
      <Helmet>
        <title>Ashmi Banerjee</title>
      </Helmet>
      <Header items={headerData} />
      <section className="Home centeredContainer">
        <div className="tile is-ancestor">
          <div className="tile is-parent is-vertical blocks">
            <div className="tile is-child box">
              <HeroCV />
            </div>
            <div className="tile is-child box">
              <p className="title" style={{ color: "#0a478a" }}>
                Recent Highlights
              </p>
              <Highlights />
            </div>
            <div className="tile is-child box">
              <p className="title" style={{ color: "#0a478a" }}>
                About Me
              </p>
              <AboutMe />
            </div>
            <div className="tile is-child box">
              <Publications />
            </div>
            <div className="tile is-child box">
              <Activities />
            </div>
            <div className="tile is-child box">
              <Miscellaneous />
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Home;
