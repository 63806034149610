export const HeaderData = [
  // {url: "/", text: "Home"},

  { url: "/#about-me", text: "About Me" },
  {
    url: "/#activities",
    text: "Activities",
    dropDown: [
      {
        text: "Talks",
        url: "#talks",
      },
      {
        text: "Services",
        url: "#academic-services",
      },
      {
        text: "Volunteering",
        url: "#volunteering",
      },
      {
        text: "Blogs",
        url: "#blogs",
      },
    ],
  },
  { url: "/#publications", text: "Publications" },
  {
    text: "CV",
    url: "/cv",
  },

];
