import FTL from "../assets/images/ftl.png";
import Arohann from "../assets/images/Arohann_white.png";
import LearnIt from "../assets/images/LearnIT.jpg";
import ACM from "../assets/images/acm.png";
import SBDevi from "../assets/images/SBDevi.jpeg";
import Ltspa from "../assets/images/ltspa.jpeg";
import Prayag from "../assets/images/prayag.jpeg"
import Greenhouse from "../assets/images/nokia.png"
import Smile from "../assets/images/smile-logo-white.jpeg";
import WTM from "../assets/images/wtm.png";
import GDE from "../assets/images/gde.png";
import WomeninRecSys from "../assets/images/Women in RecSys.png";
import reviewerLogo from "../assets/images/papers.png";
import PyconDELogo from "../assets/images/pyconDE.png";
import TUMLogo from "../assets/images/tum.jpeg"


export const MiscellaneousData = [
  {
    image: TUMLogo,
    title: "Teaching",
    category: "academic",
    ignore: false,
    duration: "2022 - present",
    company: [
      {
        name:"Technical University, Munich, Germany",
        url: "",
        location: "Munich, Germany",
      },
    ],

    desc: [
      {para: "Graduate Teaching Assistant for: "},

      {
        para:  "&#8226 [Lab Course] Hands-on Recommender Systems (SS23, SS24, WS24)"
      },
      {
        para:  "&#8226 [Seminar]  Topics in Recommender Systems (WS22, WS23)" ,

      },
    ],
  },
  {
    image: reviewerLogo,
    title: "Reviewing",
    category: "academic",
    ignore: false,
    duration: "2024 - present",
    company: [
      {
        name:"Workshops, Journals",
        url: "",
        location: "Remote",
      },
    ],

    desc: [
      {
        para:  "&#8226 ACM RecSys Workshops (FaccTRec 2024," +
          " RecSoGood" +
          " 2024)," ,

      },
      {
        para:  "&#8226 Journals: Springer Journal in Information Technology" +
          " & Tourism" +
          " (JITT)," +
          " Knowledge and Information Systems (KnoSys)"
      }
    ],
  },
  {
    image: PyconDELogo,
    title: "Member of the Program Committee",
    duration: "2024 - 2025",
    category: "academic",
    ignore: false,
    company: [
      {
        name: "Pycon & Pydata Deutschland (PyconDE 2025)",
        url: "https://2025.pycon.de/",
        location: "Remote / Darmstadt (Germany)"
      }
    ],

    desc: [
      {
        para:  "Involved in the general organization and program planning of" +
          " Pycon DE 2025.",

      },
    ],
  },

  {
    image: GDE,
    title: "ML Google Developer Expert (GDE)",
    duration: "2023 - present",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "Google Developer Experts",
        url: "https://developers.google.com/profile/u/ashmib",
        location: "Remote",
      },
    ],

    desc: [
      {
        para: "The Google Developer Experts (GDE) program is a global network of highly experienced technical experts, influencers, and thought leaders who have expertise in Google technologies, are active leaders in the space, are natural mentors, and contribute to the wider developer and startup ecosystem.The Google Developer Experts (GDE) program is a global network of highly experienced technical experts, influencers, and thought leaders who have expertise in Google technologies, are active leaders in the space, are natural mentors, and contribute to the wider developer and startup ecosystem.",
      },
    ],
  },
  {
    image: WomeninRecSys,
    title: "Organization Committee (Co-Chair)",
    duration: "Since 2023",
    ignore: false,
    category: "academic",
    company: [
      {
        name: "ACM Women in RecSys Chapter",
        url: "",
        location: "Remote",
      },
    ],

    desc: [
      {
        para: "Member of the organising team, co-chair.",
      },
    ],
  },
  {
    image: WTM,
    title: "Global Ambassador",
    duration: "2022 - present",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "Google Women Techmakers",
        url: "https://developers.google.com/womentechmakers",
        location: "Remote",
      },
    ],

    desc: [
      {
        para: "Selected as a Women Techmakers Ambassador among thousands of applications for contributions to tech and open-source.",
      },
      {
        para: "Women Techmakers Ambassadors are leaders around the world who are passionate about empowering their communities through organizing events, public speaking, creating content, and mentoring. With access to a global community and exclusive resources, Ambassadors are helping build a world where all women can thrive in tech.",
      },
    ],
  },
  {
    image: FTL,
    title: "Volunteer, Team Hackathons",
    duration: "2017 - 2022",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "Female Tech Leaders",
        url: "https://hackathons.femaletechleaders.org/",
        location: "Munich, Germany",
      },
    ],

    desc: [
      {
        para: "Member of the organising team. Also co-developed the website for the Hackathons Team.",
      },
    ],
  },
  {
    image: Arohann,
    title: "Arohann COVID Relief",
    duration: "2021",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "Non-profit Organisation",
        url: "https://arohann-f7c68.firebaseapp.com/",
        location: "Remote",
      },
    ],

    desc: [
      {
        para: "Co-founded Arohann, during the second wave of the pandemic, to help provide relief during these unprecedented times by collating and maintaining a comprehensive pool of Covid-related leads and resources, as well as to provide voluntary assistance in verifying and connecting to those leads.",
      },
    ],
  },
  {
    image: Smile,
    title: "Voluntary Choir Singer",
    duration: "September 2019 - March 2020",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "Smile Munich",
        url: "https://smilemunich.wordpress.com/about/",
        location: "Munich, Germany",
      },
    ],

    desc: [
      {
        para: "SMILE (Singing Music In the Language of English) is an English-speaking choir which creates an opportunity for foreign nationals to have the chance to give something to their local society without speaking German. ",
      },
      {
        para: "This choir sings for the benefit of groups who are unable to venture out of their environment to find joy; such as: children in hospitals, or people in elderly centers and sheltered centers in the greater Munich area.",
      },
    ],
  },
  {
    image: LearnIt,
    title: "Mentor",
    duration: "March 2018 - May 2018",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "Learn IT, Girl",
        url: "https://www.learnitgirl.com/",
        location: "Remote",
      },
    ],

    desc: [
      {
        para: "Mentoring a female candidate to develop an application in Python.",
      },
    ],
  },
  {
    image: Greenhouse,
    title: "Panelist",
    duration: "2018",
    category: "volunteer",
    ignore: true,
    company:
      [
        {
          name: "Nokia Annual Greenhouse Event",
          url: "https://www.nokia.com/blog/gender-diversity-way-go/",
          location: "Munich, Germany"
        }


      ],

    desc:
      [
        { para: "Invited as a panelist at Nokia Deutschland to speak about under-representation of women in tech at Nokia's exclusive annual Greenhouse event in Munich, Germany. Was the youngest and only student in the panel." }
      ]

  },
  {
    image: ACM,
    title: "Volunteer",
    duration: "2014 - 2016",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "ACM Student's Chapter",
        url: "https://www.facebook.com/acm.hitk/",
        location: "Kolkata, India",
      },
    ],

    desc: [
      {
        para: "Chairperson, Heritage Institute of Technology, Kolkata, India (2015-2016).",
      },
      {
        para: "Webmaster, Heritage Institute of Technology, Kolkata, India (2014-2015).",
      },
    ],
  },
  {
    image: Ltspa,
    title: "Second Runners up: Swimming",
    duration: "2012, 2015",
    ignore: true,
    category: "volunteer",
    company:
      [
        {
          name: "Lake Town Swimming Pool Association (Govt. of West Bengal)",
          url: "http://swimminginkolkata.weebly.com/lake-town-swimming-pool-association.html#/",
          location: "Kolkata, India"
        }


      ],
    desc: [{
      para: "Two times second runners up in 50 metres back-stroke under Women's Open Category in inter-club Annual Aquatic Meet, organized by Lake Town Swimming Pool (Government of West Bengal, India) in 2015 and 2012."
    }]
  },
  {
    image: SBDevi,
    title: "Volunteer",
    duration: "2013",
    category: "volunteer",
    ignore: false,
    company: [
      {
        name: "S.B. Devi Charity Home",
        url: "http://sbdevi.org/",
        location: "Kolkata, India",
      },
    ],

    desc: [
      {
        para: "Voluntary work as a part time teacher for the under-privileged children of the red-light area workers.",
      },
    ],
  },
  {
    image: Prayag,
    title: "Senior Diploma in Hindusthani Classical Music (Vocal)",
    duration: "2007",
    ignore: true,
    category: "volunteer",
    company:
      [
        {
          name: "Prayag Sangeet Samiti",
          url: "https://www.prayagsangeetsamiti.co.in/",
          location: "Allahabad, India"
        }


      ]
  }
];
