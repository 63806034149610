import React, { Component } from "react";
import "./footer.css";

export default class Footer extends Component {
  lastUpdatedDate() {
    let d = new Date();
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${months[d.getMonth()]} ${d.getFullYear()}`;
  }

  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <div className="columns is-centered" style={{ marginTop: "1%" }}>
            <a href={"/home"} style={{ color: "#12355C" }}>
              <i
                className="fas fa-home fa-1x"
                style={{
                  color: "#12355C",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              />
            </a>{" "}
            &nbsp;&nbsp;&nbsp;
            <a href={`mailto:mail@ashmibanerjee.com`}>
              <i
                className="fas fa-envelope fa-1x"
                style={{
                  color: "#12355C",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              />
              &nbsp;&nbsp;&nbsp;
            </a>
            <a href={"https://www.linkedin.com/in/ashmibanerjee"}>
              <i
                className="fab fa-linkedin fa-1x"
                style={{
                  color: "#12355C",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              />
            </a>{" "}
            &nbsp;
          </div>
          {/*<strong>*/}
          {/*    Last updated: {this.lastUpdatedDate()}*/}
          {/*</strong>{" "}*/}
          <p
            style={{
              fontFamily: "Brush Script MT, cursive",
              fontSize: "large",
              // fontWeight: "bold"
            }}
          >
            &#169; Ashmi Banerjee 2025{" "}
          </p>
        </div>
      </footer>
    );
  }
}
