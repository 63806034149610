import React from "react";
import SectionComponent from "../project-template/SectionComponent";
import { MiscellaneousData } from "../../data/MiscellaneousData";

const Volunteering = () => {
  const data = MiscellaneousData.filter(
    (item) => !item.ignore && item.category === "volunteer"
  ).map((item) => ({
    ...item,
    shouldEnableDropDown: !["teaching", "reviewer"].includes(item.title.toLowerCase()), // Add the new key-value pair
  }));
  const ROWS_TO_DISPLAY = 3;
  return (
    <div id={"volunteering"} className="content rows">
      <p
        style={{
          fontSize: "1.8rem",
          fontWeight: "600",
          color: "#545e69",
          paddingTop: "2.0rem",
        }}
      >
        Volunteering
      </p>
      <SectionComponent
        data={data}
        count={ROWS_TO_DISPLAY}
        sectionTitle="Volunteering Activities"
      />
    </div>
  );
};
export default Volunteering;
