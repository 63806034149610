import Telegraph from "../assets/images/featured/Telegraph.jpeg";
import MendingThought from "../assets/images/featured/MendingThought.png";
import WWC from "../assets/images/talks/logos/WWC.jpeg";
import Pycon22 from "../assets/images/talks/logos/PyconPortugal.svg";
import iWiC from "../assets/images/talks/logos/iwic.jpeg";
import WWC_DS from "../assets/images/talks/logos/WWC_DS.jpeg";
import WAD22 from "../assets/images/talks/logos/wad.jpeg";
import GDSC from "../assets/images/talks/logos/GDSC.jpeg";
import TUM from "../assets/images/talks/logos/TUM.jpeg";
import MunichScienceSlam
  from "../assets/images/talks/logos/MunichScienceSlam.png";
import Greenhouse from "../assets/images/talks/logos/greenhouse.png";
import EWiT from "../assets/images/talks/logos/EWiT.svg";
import Mentorifi from "../assets/images/featured/Ashmi-Banerjee-Mentorifi.jpeg";
import Bicon from "../assets/images/featured/bi-2022-01-12-entrepreneurs.jpeg";
import TUMIff from "../assets/images/featured/TUM_IFF.png";
import GDG from "../assets/images/Google-Developers-rectangle.png";
import WomenInTech from "../assets/images/featured/womenInTech.png";
import OrdinaryWomen from "../assets/images/featured/ordinaryWomen.jpeg";
import DevelopHER22 from "../assets/images/developHER2022.jpeg";
import GDE from "../assets/images/GDE-sticker.gif";
import IWD from "../assets/images/talks/talks-2023/iwd-saar.webp";
import WWCApplaudHerAwards from "../assets/images/WWC-ApplaudHer.png";
import AnitB from "../assets/images/anitaBorg.jpeg";
import PyconLT from "../assets/images/pyconLT.png";
import WWCodeApplaudHer23
  from "../assets/images/featured/WWCodeApplaudHer2023.png";
import TelekomLogo from "../assets/images/telekom.png";
import UMAPLogo from "../assets/images/talks/logos/umap23.jpeg";
import FairUMAP from "../assets/images/fairumap-23.webp";
import GoogleLogo from "../assets/images/google.jpeg";
import RecSysLogo from "../assets/images/talks/logos/FAccTRec.png";
import HeritageLogo from "../assets/images/heritage.jpg";
import TFUGKolkata from "../assets/images/TFUG_Kolkata.jpeg";
import IWD2024 from "../assets/images/WTM-IWD-2024.svg";
import InvincibleInnovationShow
  from "../assets/images/talks/logos/invincibleInnovationShow.jpeg";
import KoffeeNCommerce from "../assets/images/talks/logos/KoffeeNCommerce.jpeg";

import GDSC22Talk from "../assets/documents/talks/GDSC 2022.pdf";
import WWC2022Talk from "../assets/documents/talks/WWC 2022.pdf";
import WADTalk from "../assets/documents/talks/WeRDevelopers2022.pdf";
import EWTtalk from "../assets/documents/talks/EWIT22_Ashmi Banerjee.pdf";
import BlockDataPyTalk from "../assets/documents/talks/WWCBlockDataPy2022.pdf";
import WWCTFXTalk from "../assets/documents/talks/WWC_TFX_Tutorial_2022.pdf";
import PyConPortugal22 from "../assets/documents/talks/PyconPortugal_2022.pdf";
import DevFestGlasgow22
  from "../assets/documents/talks/DevFestGlasgow 2022.pdf";
import IWDSaarSlides
  from "../assets/documents/talks/yr_2023/MLOpsWTM_IWD_Saar.pdf";
import IWDMunichSlides
  from "../assets/documents/talks/yr_2023/RS@IWD Munich.pdf";
import AnitaBSlides from "../assets/documents/talks/yr_2023/Fair-RS AnitaB.pdf";
import GDGMalmoSlides from "../assets/documents/talks/yr_2023/GDGMalmo.pdf";
import PyconLT2023Slides
  from "../assets/documents/talks/yr_2023/RS@PyconLT.pdf";
import DTHTMLWorkshopSlides
  from "../assets/documents/talks/yr_2023/HTML Basics @ Telekom.pdf";
import FairUMAPSlides
  from "../assets/documents/talks/yr_2023/FairUMAP 2023.pdf";
import FAccTRecSlides
  from "../assets/documents/talks/yr_2023/FairUMAP 2023.pdf";
import UMAPDCSlides from "../assets/documents/talks/yr_2023/DC_0673.pdf";
import GDEAward from "../assets/documents/GDE_Award.pdf";
import RSHitKSlides from "../assets/documents/talks/yr_2023/RS-Intro@HIT-K.pdf";
import TFUGKolSlides
  from "../assets/documents/talks/yr_2023/RS@TFUG Kolkata 2023.pdf";
import DevFestSaarSlides
  from "../assets/documents/talks/yr_2023/DevFest Saarland 11-2023.pdf";
import GDSCJainUniSlides
  from "../assets/documents/talks/yr_2024/GDSC_Jain_Uni.pdf";
import IWDMalagaSlides
  from "../assets/documents/talks/yr_2024/IWD Malaga 2024.pdf";
import GDSCClujNapocaSlides
  from "../assets/documents/talks/yr_2024/LLM4Rec.pdf";
import GDGBarcelonaSlides from "../assets/documents/talks/yr_2024/LLM4Rec - RAG demo.pdf"
import PATCHSlides from "../assets/documents/talks/yr_2024/PATCH 2024.pdf"
import PATCHPaper from "../assets/papers/PATCH_2024.pdf"
import UMAP24logo from "../assets/images/umap_2024_logo.png"
import Oktalogo from "../assets/images/auth0Okta.jpeg"
import RecSoGoodLogo from "../assets/images/recSoGoodLogo.png"
import RecSoGoodSlides from "../assets/documents/talks/yr_2024/RecSoGood 2024.pdf"
import ACMRecSysLogo from "../assets/images/acm_recsys.png"
import DevFestMunich24Slides from "../assets/documents/talks/yr_2024/DevFest Munich24.pdf"
import DevFestCairo24Slides from "../assets/documents/talks/yr_2024/GDGCairoSlides.pdf"

export const HighlightsData = [
  {
    duration: "December 2024",
    year: "2024",
    ignore: false,
    date: "Dec 2024",
    title:
      "Google DevFest 2024, GDG Cairo," +
      " Egypt 🇪🇬",
    organisation:
      "GDG Cairo 🇪🇬" ,
    url: "https://gdg.community.dev/events/details/google-gdg-cairo-presents-from-fridge-to-feast-building-ai-powered-recipe-apps/",
    thumbnail: GDG,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to deliver a talk on  <b' +
      ' style="color:#778492;">RAGs to Riches: Building Sustainable Travel Recommendations with RAG</b> at the' +
      ' <a' +
      ' href="https://www.linkedin.com/posts/gdg-cairo_devfestcairo-devfestcairo2024-devfestabrresponsibleai-ugcPost-7271561679165927436-_-1E?utm_source=share&utm_medium=member_desktop" style="color:#3e8ed0ff;">' +
      ' Google DevFest 2024, organized by GDG Cairo.</a>',
    links: [
      {
        url:DevFestCairo24Slides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
    ]
  },
  {
    duration: "November 2024",
    ignore: false,
    year: "2024",
    date: "Nov 2024",
    title:
      "GDG Munich's & Google WTM's Evening Reception at Google Munich office," +
      " Germany 🇩🇪",
    organisation:
      "GDG + WTM Munich 🇩🇪" ,
    url: "https://gdg.community.dev/events/details/google-gdg-munich-presents-women-techmakers-evening-reception-devfest-munich-2024/",
    thumbnail: GDG,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to deliver a talk on  <b' +
      ' style="color:#778492;">RAGs to Riches: Building Sustainable Travel Recommendations with RAG</b> at the' +
      ' <a href="https://gdg.community.dev/events/details/google-gdg-munich-presents-women-techmakers-evening-reception-devfest-munich-2024/" style="color:#3e8ed0ff;">GDG Munich & Women Techmakers' +
      ' Munich\'s' +
      ' evening reception at the Google Munich office.</a>',
    links: [
      {
        url:DevFestMunich24Slides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
    ]
  },
  {
    duration: "October 2024",
    ignore: false,
    year: "2024",
    date: "Oct 2024",
    title:
      "Session Chair at ACM RecSys conference 2024, Bari, Italy 🇮🇹",
    organisation:
      "Session Chair at ACM RecSys conference 2024, Bari, Italy 🇮🇹" ,
    url: "https://recsys.acm.org/recsys24/session-12/",
    thumbnail: ACMRecSysLogo,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Appointed as the chair for the  <b' +
      ' style="color:#778492;">Optimization & Evaluation </b> session at the' +
      ' <span style="color:#3e8ed0ff;">ACM RecSys conference 2024,' +
      ' Bari, Italy</span>, where I led the session in front of an audience' +
      ' of over 1,200 attendees.',

  },
  {
    duration: "October 2024",
    ignore: false,
    year: "2024",
    date: "Oct 2024",
    title:
      "Presented our paper at RecSoGood 2024 Workshop co-located with the" +
      " 18th ACM Conference on Recommender Systems (RecSys 2024), Bari, Italy 🇮🇹",
    organisation:
      "[RecSoGood 2024] Workshop on Recommender Systems for Sustainability" +
      " and Social Good 🇮🇹" ,
    url: "https://recsogood.github.io/recsogood24/papers.html",
    thumbnail: RecSoGoodLogo,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Presented our paper titled <b style="color:#778492;">Enhancing' +
      ' Tourism Recommender Systems for Sustainable City Trips Using' +
      ' Retrieval-Augmented Generation</b> at <span' +
      ' style="color:#3e8ed0ff;">RecSoGood' +
      ' Workshop at RecSys 2024</span> in Bari, Italy.',
    links: [
      {
        url: RecSoGoodSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://arxiv.org/abs/2409.18003",
        text: "Paper",
        icon: "fas fa-file-pdf",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "September 2024",
    ignore: false,
    year: "2024",
    date: "Sep 2024",
    title: "AI Innovation Panel at Dev-Day by Auth0 Okta 🇬🇧",
    organisation: "Auth0 by Okta 🇬🇧",
    url: "https://regionalevents.okta.com/devdaylondon24",
    thumbnail: Oktalogo,
    activityType: "speak",
    image: "fas fa-code",
    desc: "Invited to a <b>panel discussion</b> on AI innovation during the" +
      " Dev Day" +
      " at Auth0 by Okta, in London, UK.",
    location: "London, UK",
    links: [
      {
        url:"https://developerday.com/events/devday24/sessions/ai-innovation",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ]
  },
  {
    duration: "July 2024",
    ignore: false,
    year: "2024",
    date: "July 2024",
    title:
      "Presented our paper at PATCH 2024, co-located with UMAP 2024," +
      " Cagliari, Italy 🇮🇹 ",
    organisation:
      "[PATCH 2024] Workshop on Personalized Access\n" +
      "to Cultural Heritage 🇮🇹" ,
    url: "https://dl.acm.org/doi/10.1145/3631700.3664909",
    thumbnail: UMAP24logo,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Presented our paper titled <b style="color:#778492;">Green' +
      ' Destination Recommender: A Web Application to Encourage Responsible' +
      ' City Trip Recommendations</b> at <span style="color:#3e8ed0ff;">PATCH' +
      ' Workshop at UMAP 2024</span> in Cagliari, Italy.',
    links: [
      {
        url: PATCHSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: PATCHPaper,
        text: "Paper",
        icon: "fas fa-file-pdf",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "May 2024",
    ignore: false,
    year: "2024",
    date: "May 2024",
    title: "RAG Workshop at GDG Barcelona - Build with AI 🇪🇸",
    organisation: "GDG Barcelona 🇪🇸",
    url: "https://gdg.community.dev/events/details/google-gdg-barcelona-presents-applying-llms-to-recsys-building-a-rag-using-google-gemini-and-mongodb/",
    thumbnail: GDG,
    activityType: "speak",
    image: "fas fa-code",
    desc: "Conducted a <b" +
      " style=\"color:black;\">workshop</b> titled <b" +
      " style=\"color:#778492;\">Building a RAG using Google" +
      " Gemma" +
      " and" +
      " MongoDB </b> at <span style=\"color:#3e8ed0ff; \">GDG Barcelona</span>" +
      " for" +
      " their Build with AI event. ",
    location: "Barcelona, Spain",
    links: [
      {
        url: GDGBarcelonaSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info"
      },
      {
        url: "https://gist.github.com/ashmibanerjee/060fb1dcfd940ed684e35fbb402c7fff",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning"
      },
      {
        url: "https://colab.research.google.com/drive/1CviSVwnwl73ph-AhTB0Z8vYcOQrjityk#scrollTo=baA7AWdBvil5&uniqifier=1",
        text: "Code",
        icon: "fas fa-code",
        tagColor: "is-success"
      },

    ]
  },
  {
    duration: "May 2024",
    ignore: false,
    year: "2024",
    date: "May 2024",
    title: "GDSC Saarbrücken - Introductory Event 🇩🇪",
    organisation: "GDSC Saarbrücken 🇩🇪",
    url: "https://gdsc.community.dev/events/details/developer-student-clubs-saarland-university-saarbrucken-germany-presents-augmenting-llm-prompts-for-contextual-clarity-building-a-rag-system-using-gemma-amp-mongodb/",
    thumbnail: GDSC,
    activityType: "speak",
    image: "fas fa-code",
    desc: "Invited by <span style=\"color:#3e8ed0ff; \">GDSC Saarbrücken" +
      " Chapter</span> to <b" +
      " style=\"color:black;\">speak</b> on <b style=\"color:#778492;\">Applying LLMs to Recommender" +
      " Systems </b>" +
      " at their <a" +
      " href=\\'https://gdsc.community.dev/events/details/developer-student-clubs-saarland-university-saarbrucken-germany-presents-augmenting-llm-prompts-for-contextual-clarity-building-a-rag-system-using-gemma-amp-mongodb/\\'>Semester opening</a> event. ",
    location: "Saarbrücken, Germany",
    links: [
      {
        url: GDSCClujNapocaSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info"
      },
      {
        url: "https://gist.github.com/ashmibanerjee/060fb1dcfd940ed684e35fbb402c7fff",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning"
      }
    ]
  },
  {
    duration: "May 2024",
    ignore: false,
    year: "2024",
    date: "May 2024",
    title: "Build with AI: GDSC Cluj Napoca, Romania 🇷🇴",
    organisation: "GDSC Cluj Napoca 🇷🇴",
    url: "https://gdsc.community.dev/events/details/developer-student-clubs-babes-bolyai-university-cluj-napoca-romania-presents-build-with-ai/",
    thumbnail: GDSC,
    activityType: "speak",
    image: "fas fa-code",
    desc: "Invited by <span style=\"color:#3e8ed0ff; \">GDSC Cluj Napoca" +
      " Chapter</span> to <b" +
      " style=\"color:black;\">speak</b> on <b style=\"color:#778492;\">Applying LLMs to Recommender" +
      " Systems </b>" +
      " at their <a" +
      " href=\\'https://gdsc.community.dev/events/details/developer-student-clubs-babes-bolyai-university-cluj-napoca-romania-presents-build-with-ai\\'>Build with AI</a> event. ",
    location: "Cluj Napoca, Romania",
    links: [
      {
        url: GDSCClujNapocaSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info"
      },
      {
        url: "https://gist.github.com/ashmibanerjee/060fb1dcfd940ed684e35fbb402c7fff",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning"
      }
    ]
  },
  {
    duration: "March 2024",
    ignore: false,
    year: "2024",
    date: "Mar 2024",
    title: "Women Who Code Data Science Mentorship Programme 2024",
    organisation: "KoffeeNCommerce",
    url: "https://www.linkedin.com/posts/koffeencommerce_womeninstem-breakingbarriers-impactthefuture-activity-7174476250026061825-Wgd4/?utm_source=share&utm_medium=member_desktop",
    thumbnail: WWC_DS,
    activityType: "mentor",
    image: "fas fa-code",
    desc: 'Invited by <span style=\"color:#3e8ed0ff; \">Women Who Code Data Science Chapter</span> to <b' +
      ' style="color:black;">mentor</b> a group of 4-7' +
      ' mentees on different career pathways in Data Science - understanding' +
      ' the Data Science and ML landscape, identifying personal goals, job' +
      ' searching, etc. for a period of 6 weeks.'
  },
  {
    duration: "March 2024",
    ignore: false,
    year: "2024",
    date: "Mar 2024",
    title: "Breaking Barriers: Women in STEM Shaping the Future - Panel" +
      " Discussion",
    organisation: "KoffeeNCommerce",
    url: "https://www.linkedin.com/posts/koffeencommerce_womeninstem-breakingbarriers-impactthefuture-activity-7174476250026061825-Wgd4/?utm_source=share&utm_medium=member_desktop",
    thumbnail: KoffeeNCommerce,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to join a <b style="color:black;">panel</b> on' +
      ' <b style="color:#778492;">Breaking Barriers: Women in STEM Shaping the Future' +
      ' </b> at' +
      ' the International Women\'s Day celebration' +
      ' organised by' +
      ' <span' +
      ' style="color:#3e8ed0ff;">KoffeeNCommerce.' +
      ' </span>',
  },
  {
    duration: "March 2024",
    ignore: false,
    year: "2024",
    date: "Mar 2024",
    activityType: "podcast",
    organisation: "Invincible Innovation: Unveiling Bias: Navigating AI's Fairness Frontier",
    thumbnail: InvincibleInnovationShow,
    title:
      "<a style=\"color: black; \"" +
      " href='https://www.youtube.com/watch?v=WJiWnAFK7WQ&ab_channel=InvincibleInnovation'>Recorded a podcast for Invincible Innovation</a>",
    url: "https://www.youtube.com/watch?v=WJiWnAFK7WQ&ab_channel=InvincibleInnovation",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: 'Recorded a <b style="color:black;">podcast</b> titled <b>Unveiling Bias: Navigating ' +
      ' AI\'s Fairness Frontier</b> for the <a' +
      ' style="color:#3e8ed0ff; "' +
      ' href=\'https://www.youtube.com/watch?v=WJiWnAFK7WQ&ab_channel=InvincibleInnovation\'>Invincible Innovation Show</a>.',
    links: [
      {
        url: "https://open.spotify.com/show/3YPor4R2whDXXCtKUPHqrF?si=86b56391d89d4486",
        text: "Spotify",
        icon: "fab fa-spotify",
        tagColor: "is-info",
      },
      {
        url: "https://www.linkedin.com/events/7172566307307937793/comments/",
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-warning",
      },
      {
        url:"https://www.youtube.com/watch?v=WJiWnAFK7WQ&ab_channel=InvincibleInnovation",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "March 2024",
    ignore: false,
    year: "2024",
    date: "Mar 2024",
    title: "Demystifying LLMs with Google’s Generative AI Toolkit 🇪🇸",
    organisation: "Women Techmakers, Málaga 🇪🇸",
    url: "https://gdg.community.dev/events/details/google-gdg-malaga-presents-iwd-2024-by-wtm-malaga/",
    thumbnail: IWD2024,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to <b style="color:black;">speak</b> on' +
      ' <b style="color:#778492;">Demystifying LLMs with Google’s Generative AI Toolkit' +
      ' </b> at' +
      ' the International Women\'s day (IWD)' +
      ' event organised by' +
      ' <span' +
      ' style="color:#3e8ed0ff;">Women Techmakers, Málaga.' +
      ' </span>',
    links: [
      {
        url: IWDMalagaSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/1823c8c2aa55c9caf2447b3596a2d560",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "February 2024",
    ignore: false,
    year: "2024",
    date: "Feb 2024",
    title: "Accelerating Your AI/ML Career: Mentorship, Strategies, and" +
      " Success Stories - Panel Discussion 🇮🇳",
    organisation: "TensorFlow User Group (TFUG), Kolkata 🇮🇳",
    url: "https://twitter.com/TFUGKol/status/1754569228055663081?t=v0e4OsLRCFz8u9A6VVuHAg&s=19",
    thumbnail: TFUGKolkata,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to join a <b style="color:black;">panel</b> on' +
      ' <b style="color:#778492;">Accelerating Your AI/ML Career' +
      ' </b> at' +
      ' the February' +
      ' meetup organised by' +
      ' <span' +
      ' style="color:#3e8ed0ff;">TensorFlow User Group Kolkata.' +
      ' </span>',
  },
  {
    duration: "February 2024",
    ignore: false,
    year: "2024",
    date: "Feb 2024",
    activityType: "speak",
    organisation: "Google Developers Students Club (GDSC), FET Jain University, Bengaluru",
    thumbnail: GDSC,
    title:
      "<a style=\"color: black; \"" +
      " href='https://gdsc.community.dev/events/details/developer-student-clubs-fet-jain-university-bengaluru-presents-exploring-the-magic-behind-recommender-systems/'>Invited to conduct an ML Study jam session, organised" +
      " by GDSC Jain University.</a>",
    url: "https://gdsc.community.dev/events/details/developer-student-clubs-fet-jain-university-bengaluru-presents-exploring-the-magic-behind-recommender-systems/",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: 'Invited to conduct an <b style="color:black;">ML Study jam session</b>' +
      ' titled' +
      ' <b>Exploring the magic behind Recommender Systems</b> for' +
      ' <a' +
      ' style="color:#3e8ed0ff; "' +
      ' href=\'https://gdsc.community.dev/events/details/developer-student-clubs-fet-jain-university-bengaluru-presents-exploring-the-magic-behind-recommender-systems\'>GDSC Jain University</a>.',
    links: [
      {
        url: GDSCJainUniSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/1823c8c2aa55c9caf2447b3596a2d560",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "November 2023",
    ignore: false,
    year: "2023",
    date: "Nov 2023",
    activityType: "speak",
    organisation: "Google Developers Students Club (GDSC), Saarland" +
      " University 🇩🇪",
    thumbnail: GDSC,
    title:
      "<a style=\"color: black; \"" +
      " href='https://gdsc.community.dev/events/details/developer-student-clubs-saarland-university-presents-bridging-data-amp-ai/'>Invited to deliver a talk at DevFest Saarland, organised" +
      " by GDSC Saarland University.</a>",
    url: "https://gdsc.community.dev/events/details/developer-student-clubs-saarland-university-presents-bridging-data-amp-ai/",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: 'Invited to deliver a <b style="color:black;">talk</b>' +
      ' titled' +
      ' <b>Exploring VertexAI and Generative AI Toolkit from Google</b> for' +
      ' <a' +
      ' style="color:#3e8ed0ff; "' +
      ' href=\'https://gdsc.community.dev/events/details/developer-student-clubs-saarland-university-presents-bridging-data-amp-ai/\'>GDSC Saarland University</a>.',
    links: [
      {
        url: DevFestSaarSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/1823c8c2aa55c9caf2447b3596a2d560",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "November 2023",
    ignore: false,
    year: "2023",
    date: "Nov 2023",
    activityType: "podcast",
    organisation: "Women Who Code Podcast: Fairness and Bias in Recommendation Systems",
    thumbnail: WWC,
    title:
      "<a style=\"color: black; \"" +
      " href='https://www.womenwhocode.com/blog/talks-tech-51-fairness-and-bias-in-recommendation-systems/'>Recorded a podcast for Women Who Code</a>",
    url: "https://www.womenwhocode.com/blog/talks-tech-51-fairness-and-bias-in-recommendation-systems/",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: 'Recorded a <b style="color:black;">podcast</b> titled <b>Fairness' +
      ' and Bias in Recommendation Systems</b> for <a' +
      ' style="color:#3e8ed0ff; "' +
      ' href=\'https://www.womenwhocode.com/blog/talks-tech-51-fairness-and-bias-in-recommendation-systems\'>Women Who Code</a>.',
    links: [
      {
        url: "https://open.spotify.com/episode/49cuCGX1A1poSdHsurrY7Z",
        text: "Spotify",
        icon: "fab fa-spotify",
        tagColor: "is-info",
      },
      {
        url: "https://www.womenwhocode.com/blog/talks-tech-51-fairness-and-bias-in-recommendation-systems/",
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-warning",
      },
      {
        url:"https://www.youtube.com/watch?v=1XBa_zpJTf4&ab_channel=WomenWhoCode",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "September 2023",
    ignore: false,
    year: "2023",
    date: "Sept 2023",
    title: "Recommender Systems: Cracking the Code of Content \n",
    organisation: "TensorFlow User Group (TFUG), Kolkata 🇮🇳",
    url: "https://x.com/TFUGKol/status/1707894814820421762?s=20",
    thumbnail: TFUGKolkata,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to deliver a <b style="color:black;">talk</b> titled' +
      ' <b style="color:#778492;">Recommender Systems: Cracking the Code of' +
      ' Content</b> at' +
      ' the September' +
      ' meetup organised by' +
      ' <span' +
      ' style="color:#3e8ed0ff;">TensorFlow User Group Kolkata' +
      ' </span>.',
    links: [
      {
        url: TFUGKolSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/d226371135093b8d4a840f11adbb1828",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "September 2023",
    ignore: false,
    year: "2023",
    date: "Sept 2023",
    title: "Lecture on Recommender Systems",
    organisation: "Heritage Institute of Technology, Kolkata 🇮🇳",
    url: "",
    thumbnail: HeritageLogo,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to conduct a <b style="color:black;">lecture</b> on' +
      ' Recommender Systems at the  <span style="color:#3e8ed0ff;">ACM Students\' Chapter at' +
      ' Heritage Institute of Technology, Kolkata.</span>',
    links: [
      {
        url: RSHitKSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/d226371135093b8d4a840f11adbb1828",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "September 2023",
    ignore: false,
    year: "2023",
    date: "Sept 2023",
    activityType: "speak",
    organisation: "[FAccTRec 2023] Workshop on Responsible Recommendations",
    thumbnail: RecSysLogo,
    title:
     " <a style=\"color: black; \" href='${GDEAward}'> Presented our" +
      " Position Paper at FAccTRec 2023, Singapore</a>",
    url: "",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: "Presented our Position paper titled <b style=\"color:#778492;\">Towards Individual and" +
      " Multistakeholder Fairness in Tourism Recommender Systems </b> at" +
      " <span style=\"color:#3e8ed0ff;\">Workshop on Responsible" +
      " Recommendations (FAccTRec 2023)</span>," +
      " co-located" +
      " with the 16th ACM Conference on Recommender Systems (RecSys)" +
      " September 18-22, 2023, Singapore. ",
    links: [
      {
        url: FAccTRecSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://arxiv.org/abs/2309.02052",
        text: "Paper",
        icon: "fas fa-file-pdf",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "June 2023",
    ignore: false,
    year: "2023",
    date: "June 2023",
    activityType: "featured",
    organisation: "Google Developer Expert Community Award (Rising Star)",
    thumbnail: GoogleLogo,
    title:
      `<a style=\"color: black; \" href='${GDEAward}'> Google Developer Expert` +
      " Community Award (Rising Star)</a>",
    url: "",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: "Awarded the <a href=''>Google Developer Expert Community Award</a> in" +
      " the <b>Rising Star</b> category in recognition of her contributions to the developer community for the year 2022-23.",
    links: [
      {
        url: GDEAward,
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "June 2023",
    ignore: false,
    year: "2023",
    date: "June 2023",
    title:
      "Presented my doctoral thesis proposal at UMAP-DC, co-located with UMAP 2023, Limassol, Cyprus ",
    organisation:
      "[UMAP-DC 2023] ACM Conference on User Modeling, Adaptation and" +
      " Personalization 🇨🇾",
    url: "https://dl.acm.org/doi/abs/10.1145/3565472.3595607",
    thumbnail: UMAPLogo,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Presented my Doctoral Thesis Proposal titled <b style="color:#778492;">Fairness and Sustainability in Multistakeholder Tourism Recommender Systems</b> at <span style="color:#3e8ed0ff;">UMAP 2023</span> in Limassol, Cyprus.',
    links: [
      {
        url: UMAPDCSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/94e872932f14a8bbddf79c52debae7fd",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://ashmibanerjee.com/static/media/umapdc-23.13b143a97100962ad00b.pdf",
        text: "Paper",
        icon: "fas fa-file-pdf",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "June 2023",
    ignore: false,
    year: "2023",
    date: "June 2023",
    title:
      "Presented our paper at FairUMAP 2023, co-located with UMAP 2023, Limassol, Cyprus ",
    organisation:
      "[FairUMAP 2023] Workshop on Fairness in User Modeling, Adaptation," +
      " and Personalization 🇨🇾",
    url: "https://dl.acm.org/doi/10.1145/3563359.3597442",
    thumbnail: FairUMAP,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Presented our paper titled <b style="color:#778492;">Understanding User Perspectives on Sustainability and Fairness in Tourism Recommender Systems</b> at <span style="color:#3e8ed0ff;">FairUMAP Workshop at UMAP 2023</span> in Limassol, Cyprus.',
    links: [
      {
        url: FairUMAPSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/bad3b4a5212ae8d9b27514fce22701f7",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://ashmibanerjee.com/static/media/fairumap-23.1aa2d19eae4a4afe7f5e.pdf",
        text: "Paper",
        icon: "fas fa-file-pdf",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "May 2023",
    ignore: false,
    year: "2023",
    date: "May 2023",
    title: "HTML/CSS Workshop @ Deutsche Telekom",
    organisation: "Deutsche Telekom, Germany",
    url: "",
    thumbnail: TelekomLogo,
    activityType: "speak",
    image: "fas fa-code",
    desc: 'Invited to conduct a <b style="color:black;">workshop</b> on HTML/CSS for a cohort of 70 participants, as a part of the <b>Software Development Explorer Journey</b> at <span style="color:#3e8ed0ff;">Deutsche Telekom</span>.',
    links: [
      {
        url: DTHTMLWorkshopSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://github.com/ashmibanerjee/dt-html-workshop/blob/main/README.md",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "May 2023",
    ignore: false,
    year: "2023",
    // upcoming: true,
    thumbnail: PyconLT,
    date: "May 2023",
    activityType: "speak",
    organisation: "Pycon LT 2023, Lithuania",
    title:
      "Conducted TFRS Workshop at <a style=\"color: #3e8ed0ff;\" href='https://pycon.lt/2023'>\n" +
      "Pycon LT</a>, organised by Python Community in Lithuania. ",
    url: "https://pycon.lt/2023",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Conducted a workshop titled <b style="color:#778492;">The Ultimate Matchmaker - Building Recommender Systems with Tensorflow</b> at <a href=\'https://pycon.lt/2023\' style="color:#3e8ed0ff; ">Pycon Lithuania 2023</a>.</span>',
    links: [
      {
        url: PyconLT2023Slides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/55a221df7f00efa5b764f4a7907433ad",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
          url:"https://youtu.be/MNqLBOYBsmQ?si=3vrPiQ4z6OsBF1Gs",
          text: "Video",
          icon: "fas fa-video",
          tagColor: "is-success",
      },
    ],
  },
  {
    duration: "May 2023",
    ignore: false,
    year: "2023",
    date: "May 2023",
    activityType: "featured",
    organisation: " 2023 WWCode Applaud Her Award (Data Science)",
    thumbnail: WWCodeApplaudHer23,
    title:
      "<a style=\"color: black; \" href='https://www.linkedin.com/posts/women-who-code_2023-applaud-her-award-data-science-activity-7062469066514468864-ePc_?utm_source=share&utm_medium=member_desktop'> 2023 WWCode Applaud Her Award - Data Science</a>",
    url: "https://www.linkedin.com/posts/women-who-code_2023-applaud-her-award-data-science-activity-7062469066514468864-ePc_?utm_source=share&utm_medium=member_desktop",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: "Awarded the <a>2023 WWCode Applaud Her Award (Data Science)</a> by Women Who Code, acknowledging her dedication towards bridging the gender-gap in STEM.",
    links: [
      {
        url: "https://www.linkedin.com/posts/women-who-code_2023-applaud-her-award-data-science-activity-7062469066514468864-ePc_?utm_source=share&utm_medium=member_desktop",
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "May 2023",
    ignore: false,
    year: "2023",
    // upcoming: true,
    thumbnail: IWD,
    date: "May 2023",
    activityType: "speak",
    organisation: "Women Techmakers Fest, Malmö, Sweden #IwD2023",
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://gdg.community.dev/events/details/google-gdg-malmo-presents-women-techmakers-fest-malmo-sweden-iwd2023/'>\n" +
      "Women Techmakers Fest, Malmö, Sweden #IwD2023</a>, organised by GDG Malmö and Women Techmakers. ",
    url: "https://gdg.community.dev/events/details/google-gdg-malmo-presents-women-techmakers-fest-malmo-sweden-iwd2023/",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Invited to deliver a talk titled <b style="color:#778492;">Building a More Inclusive Web - How to Create Fair Recommendation Algorithms</b> at <a href=\'https://gdg.community.dev/events/details/google-gdg-malmo-presents-women-techmakers-fest-malmo-sweden-iwd2023/\' style="color:#3e8ed0ff; ">International Women\'s Day (IWD) #DareToBe 2023</a>, organised by Google Developer Group Malmö and Women Techmakers.</span>',
    links: [
      {
        url: GDGMalmoSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/620687a3b8889278a6a61d1ee9bc80ef",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "April 2023",
    ignore: false,
    year: "2023",
    thumbnail: AnitB,
    date: "Apr 2023",
    activityType: "speak",
    organisation: "Anita B.Org Chapter in Munich",
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://membership.anitab.org/event/RAI_Germany'>\n" +
      "Responsible AI(RAI) and Recommendation Systems</a>, organised by Anita Borg Chapter in Munich. ",
    url: "https://membership.anitab.org/event/RAI_Germany",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Invited to deliver a talk titled <b style="color:#778492;">Building a More Inclusive Web - How to Create Fair Recommendation Algorithms</b> at <a href=\'https://membership.anitab.org/event/RAI_Germany\' style="color:#3e8ed0ff; ">Responsible AI and Recommendation Systems Meetup</a>, organised by AnitaB Chapter Munich.</span>',
    links: [
      {
        url: AnitaBSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/620687a3b8889278a6a61d1ee9bc80ef",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "March 2023",
    ignore: false,
    year: "2023",
    date: "Mar 2023",
    activityType: "featured",
    organisation:
      "Women Who Code Applaud Her Awards - 100 Technologists to Watch",
    thumbnail: WWCApplaudHerAwards,
    title:
      "<a style=\"color: black; \" href='https://www.womenwhocode.com/100-technologists-to-watch?mc_cid=4dd7b3516a&mc_eid=99ae716a8c'>Women Who Code Applaud Her Awards - 100 Technologists to Watch</a>",
    url: "https://www.womenwhocode.com/100-technologists-to-watch?mc_cid=4dd7b3516a&mc_eid=99ae716a8c",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: "Recognised as one of the <a>100 technologists to watch in 2023</a> by Women Who Code.",
    links: [
      {
        url: "https://www.womenwhocode.com/100-technologists-to-watch?mc_cid=4dd7b3516a&mc_eid=99ae716a8c",
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "March 2023",
    ignore: false,
    year: "2023",
    // upcoming: true,
    thumbnail: IWD,
    date: "Mar 2023",
    activityType: "speak",
    organisation:
      "International Women's Day (IWD) #DareToBe 2023 by GDG Munich + Women Techmakers",
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://gdg.community.dev/events/details/google-gdg-munich-presents-international-womens-day-iwd-daretobe-2023-by-gdg-munich/'>\n" +
      "International Women's Day (IWD) #DareToBe 2023</a>, organised by GDG Munich and Women Techmakers. ",
    url: "https://gdg.community.dev/events/details/google-gdg-munich-presents-international-womens-day-iwd-daretobe-2023-by-gdg-munich/",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Invited to deliver a talk titled <b style="color:#778492;">From Netflix and Chill to Amazon Prime Time - Exploring the magic behind Recommender Systems\n</b> at <a href=\'https://gdg.community.dev/events/details/google-gdg-munich-presents-international-womens-day-iwd-daretobe-2023-by-gdg-munich/\' style="color:#3e8ed0ff; ">International Women\'s Day (IWD) #DareToBe 2023</a>, organised by Google Developer Group Munich and Women Techmakers.</span>',
    links: [
      {
        url: IWDMunichSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/5ee701329b96095a27cdae03a54f4c0e",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "March 2023",
    ignore: false,
    year: "2023",
    // upcoming: true,
    thumbnail: IWD,
    date: "Mar 2023",
    activityType: "speak",
    organisation:
      "International Women's Day, GDG Saarbrücken + Women Techmakers",
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://gdg.community.dev/events/details/google-gdg-saarbrucken-presents-international-womens-day-saar-wtmdaretobe/'>International Women's Day</a>, organised by GDG Saarbrücken and Women Techmakers. ",
    url: "https://gdg.community.dev/events/details/google-gdg-saarbrucken-presents-international-womens-day-saar-wtmdaretobe/",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Invited to deliver a talk' +
      ' titled <b style="color:#778492;">Demystifying MLOps - Propelling Models from Prototypes to Production</b> at <a href=\'https://gdg.community.dev/events/details/google-gdg-saarbrucken-presents-international-womens-day-saar-wtmdaretobe/\' style="color:#3e8ed0ff; ">International Women\'s Day</a>, organised by Google Developer Group Saarbrücken and Women Techmakers.</span>',
    links: [
      {
        url: IWDSaarSlides,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/474e041b56a113d9e67d9ba581cd54f1",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "January 2023",
    ignore: false,
    year: "2023",
    date: "Jan 2023",
    activityType: "featured",
    organisation: "Google Developers Expert (GDE)",
    thumbnail: GDE,
    title:
      "<a style=\"color: black; \" href='https://developers.google.com/community/experts'>Became a Google Developer Expert in Machine Learning</a>",
    url: "https://developers.google.com/community/experts",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: "Became a <a href='https://developers.google.com/community/experts'>Google Developer Expert (GDE)</a> in Machine Learning (ML).",
    links: [
      {
        url: "https://developers.google.com/community/experts",
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "November 2022",
    ignore: false,
    year: "2022",
    date: "Nov 2022",
    activityType: "featured",
    organisation:
      "Awarded with the DevelopHER Awards (Emerging Talent) by DevelopHER UK.",
    thumbnail: DevelopHER22,
    title:
      "<a style=\"color: black; \" href='https://developher.co.uk/developher-winners-2022/'>Awarded with the DevelopHER Awards in the Emerging Talent Category by DevelopHER UK.</a>",
    url: "https://developher.co.uk/developher-winners-2022/",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: '<b style="color:black;">Awarded</b> with the  <a style="color:#3e8ed0ff; " href=\'https://developher.co.uk/developher-winners-2022/\'>DevelopHER Awards</a> in the Emerging Talent Category by DevelopHER UK.',
    links: [
      {
        url: "https://developher.co.uk/developher-winners-2022/",
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "November 2022",
    ignore: false,
    year: "2022",
    date: "Nov 2022",
    activityType: "podcast",
    organisation: "Podcast by Ordinary Women: A new country to call home",
    thumbnail: OrdinaryWomen,
    title:
      "<a style=\"color: black; \" href='https://open.spotify.com/episode/5uPIWiRdhojofNrFYJrrPG?si=HfqFUxYRQy-B1JGUxmusKw'>Recorded a podcast for Ordinary Women</a>",
    url: "https://open.spotify.com/episode/5uPIWiRdhojofNrFYJrrPG?si=HfqFUxYRQy-B1JGUxmusKw",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: 'Recorded a <b style="color:black;">podcast</b> for <a style="color:#3e8ed0ff; " href=\'https://open.spotify.com/episode/5uPIWiRdhojofNrFYJrrPG?si=HfqFUxYRQy-B1JGUxmusKw\'>Ordinary Women</a> hosted by Lucie Bigaud.',
    links: [
      {
        url: "https://open.spotify.com/episode/5uPIWiRdhojofNrFYJrrPG?si=HfqFUxYRQy-B1JGUxmusKw",
        text: "Spotify",
        icon: "fab fa-spotify",
        tagColor: "is-info",
      },
      {
        url: "https://linktr.ee/ordinarywomen",
        text: "Links",
        icon: "fas fa-link",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "November 2022",
    ignore: false,
    year: "2022",
    // upcoming: true,
    thumbnail: GDG,
    date: "Nov 2022",
    activityType: "speak",
    organisation: "Google DevFest Glasgow, Scotland",
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://gdg.community.dev/events/details/google-gdg-glasgow-presents-google-devfest-2022/'>Google DevFest Glasgow</a>, Scotland ",
    url: "https://gdg.community.dev/events/details/google-gdg-glasgow-presents-google-devfest-2022/",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Delivered a talk titled <b style="color:#778492;">Destroy the Dilemma - REST vs gRPC during model serving using TensorFlow Serving </b> at <a href=\'https://gdg.community.dev/gdg-glasgow/\' style="color:#3e8ed0ff; ">Google DevFest Glasgow 2022</a>, Scotland.</span>',
    links: [
      {
        url: DevFestGlasgow22,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/5ecbe61eff22306d0b4b4efe0ac65874",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://youtu.be/LQmXuqgbFyw",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "September-October 2022",
    ignore: false,
    year: "2022",
    thumbnail: WWC_DS,
    date: "Sep-Oct 2022",
    activityType: "speak",
    organisation: "Women Who Code (WWC): Data Science, Remote",
    title:
      "Hosted and co-organised a virtual interview series <b> WWCode Carrier Cruises</b>",
    url: "https://gdg.community.dev/events/details/google-gdg-glasgow-presents-google-devfest-2022/",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Hosted and co-organised <b style="color:#778492;">Carrier Cruises</b> - a virtual interview series to guide through tech career development, together with <a style="color: #3e8ed0ff;" href=\'https://www.womenwhocode.com/datascience\'>Women Who Code - Data Science track</a>.',
    links: [
      {
        url: "https://www.youtube.com/playlist?list=PLVcEZG2JPVhcNs-s1-7z0-wQRkJST5P82",
        text: "Playlist",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "September 2022",
    ignore: false,
    year: "2022",
    date: "Sep 2022",
    activityType: "featured",
    thumbnail: WomenInTech,
    organisation: "Women in Tech e.V.",
    title:
      "<a style=\"color: black;  \" href='https://www.womenintechev.de/en/interview-with-ashmi-banerjee/'>Women in Tech: Interview with Ashmi Banerjee </a>",

    url: "https://www.womenintechev.de/en/interview-with-ashmi-banerjee/",
    image: "fas fa-blog",
    desc: 'Featured on <span style="color:#3e8ed0ff;">Women in Tech in Deutschland, Österreich, Schweiz </span> <a style="color: black; ; font-weight: bold" href=\'https://www.womenintechev.de/en/interview-with-ashmi-banerjee/\'>Interview series</a>.',
    links: [
      {
        url: "https://www.womenintechev.de/en/interview-with-ashmi-banerjee/",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "September 2022",
    ignore: false,
    year: "2022",
    // upcoming: true,
    thumbnail: Pycon22,
    date: "Sep 2022",
    activityType: "speak",
    organisation: "Pycon Portugal, Porto",
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://2022.pycon.pt/'>Pycon Portugal 2022</a>, Porto ",
    url: "https://2022.pycon.pt/",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Delivered a talk titled <b style="color:#778492;">Automating mundane activities using Python </b> at <a href=\'https://2022.pycon.pt/\' style="color:#3e8ed0ff; ">Pycon Portugal 2022</a>, Porto.</span>',
    links: [
      {
        url: PyConPortugal22,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/c375915d2e1e06bd4e544ea151831e8b",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://youtu.be/zuLL9MaiyTw",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "September 2022",
    ignore: false,
    year: "2022",
    // upcoming: true,
    thumbnail: GDG,
    // date: "Sep 2022 <i style=\"color: #778492;font-size: small; \">[upcoming]</i>",
    date: "Sep 2022",
    activityType: "others",
    organisation: "Google Developers Community",
    title:
      "Invited to attend <a style=\"color: #3e8ed0ff;\" href='https://developers.google.com/'>Google Developers Community Summit 2022</a>, in Warsaw, Poland. ",
    url: "https://developers.google.com/",
    image: "fas fa-chalkboard-teacher",
    desc: "<span style=\"word-break: break-word;\">Invited to attend <a href='https://developers.google.com/'>Google Developers Community Summit 2022 </a> an event which brings together GDE, WTM ambassadors, GDSC and GDG leads. The event took place from <b>September 16th to 18th, 2022</b> in Warsaw, Poland.</span>",
  },
  {
    duration: "August 2022",
    ignore: false,
    year: "2022",
    date: "Aug 2022",
    activityType: "featured",
    thumbnail: Mentorifi,
    organisation: "Mentorifi - The Eventible Blog",
    title:
      "<a style=\"color: black;  \" href='https://mentorifi.eventible.com/speakers-corner-featuring-ashmi-banerjee-data-scientist-iii-deutsche-telekom/'>Speaker's Corner featuring Ashmi Banerjee, Data Scientist, Deutsche Telekom </a>",

    url: "https://mentorifi.eventible.com/speakers-corner-featuring-ashmi-banerjee-data-scientist-iii-deutsche-telekom/",
    image: "fas fa-blog",
    desc: 'Featured on <span style="color:#3e8ed0ff;">Speaker\'s Corner at Mentorifi - The Eventible </span> <a style="color: black; ; font-weight: bold" href=\'https://mentorifi.eventible.com/speakers-corner-featuring-ashmi-banerjee-data-scientist-iii-deutsche-telekom/\'>Blog</a> for my speaking engagements.',
    links: [
      {
        url: "https://mentorifi.eventible.com/speakers-corner-featuring-ashmi-banerjee-data-scientist-iii-deutsche-telekom/",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "August 2022",
    ignore: false,
    year: "2022",
    date: "Aug 2022",
    activityType: "speak",
    thumbnail: WWC_DS,
    organisation: "Women Who Code (WWC): Data Science, Remote",
    // descV2: 1,
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://us02web.zoom.us/webinar/register/WN_h2J0lpWiTOewK_wgkFP29g'>Women Who Code (WWC): Data Science</a>, Remote ",
    url: "https://hopin.com/events/connect-recharge-2022/registration",
    image: "fas fa-chalkboard-teacher",
    desc: 'Invited to deliver a workshop on <b style="color:#778492;">Serving ML Models as REST API using TensorFlow Serving </b> at <a href=\'https://us02web.zoom.us/webinar/register/WN_h2J0lpWiTOewK_wgkFP29g\' style="color:#3e8ed0ff; ">Women Who Code (WWC): Data Science</a>.',
    links: [
      {
        url: WWCTFXTalk,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/5ecbe61eff22306d0b4b4efe0ac65874",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://youtu.be/MVLO5j4hCLA",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "July 2022",
    ignore: false,
    year: "2022",
    date: "Jul 2022",
    activityType: "speak",
    thumbnail: WWC,
    organisation: "Women Who Code (WWC), Remote",
    descV2: 1,
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://hopin.com/events/women-who-code-tech-summit-blockdatapy/registration?mc_cid=6dd25de006&mc_eid=UNIQID'>Women Who Code (WWC) Tech Summit: BlockDataPy</a>, Remote ",
    url: "https://hopin.com/events/connect-recharge-2022/registration",
    image: "fas fa-chalkboard-teacher",
    desc: 'Delivered a talk titled <b style="color:#778492;">Demystifying MLOps: Propelling Models from Prototypes to Production </b> at <a href=\'https://hopin.com/events/women-who-code-tech-summit-blockdatapy/registration?mc_cid=6dd25de006&mc_eid=UNIQID\' style="color:#3e8ed0ff; ">Women Who Code (WWC) Tech Summit: BlockDataPy</a>.',
    links: [
      {
        url: BlockDataPyTalk,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/88787f583f26e825fcb7a37ec1a16731",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://youtu.be/gBMy4JOfPj8",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "July 2022",
    ignore: false,
    year: "2022",
    activityType: "speak",
    thumbnail: iWiC,
    date: "Jul 2022",
    // descV2: 1,
    organisation: "Anita Borg's Indian Women in Computing (IWiC)",
    title:
      "Panelist at <a href='https://www.indianwic.org/' style=\"color:#3e8ed0ff; \">Anita Borg's Indian Women in Computing (IWiC)</a> event",
    url: "https://www.linkedin.com/posts/indianwic_iwic-volunteer-volunteering-activity-6946057258149523456-9Dky?utm_source=linkedin_share&utm_medium=member_desktop_web",
    image: "fas fa-users",
    desc: 'Invited by <a href=\'https://www.indianwic.org/\' style="color:#3e8ed0ff; ">Anita Borg\'s Indian Women in Computing (IWiC)</a> to join a panel on <b style="color:#778492;">Volunteering in Tech</b>',
    links: [
      {
        url: "https://www.linkedin.com/posts/indianwic_iwic-volunteer-volunteering-activity-6946057258149523456-9Dky?utm_source=linkedin_share&utm_medium=member_desktop_web",
        text: "LinkedIn",
        icon: "fab fa-linkedin",
        tagColor: "is-info",
      },
      {
        url: "https://www.youtube.com/watch?v=WJ-rpdWMqSI&t=10s&ab_channel=IndianWomenInComputing",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "June 2022",
    ignore: false,
    year: "2022",
    activityType: "speak",
    thumbnail: WWC_DS,
    date: "Jun 2022",
    // descV2: 1,
    organisation: "Women Who Code (WWC) Data Science: Instagram Live",
    title:
      'Invited by <a href=\'https://www.instagram.com/wwcodedatascience/\' style="color:#3e8ed0ff; ">Women Who Code (WWC) Data Science Track</a> for an <b style="color:#778492;">Instagram Live</b>',
    url: "https://www.instagram.com/wwcodedatascience/",
    image: "fas fa-chalkboard-teacher",
    desc: 'Invited by <a href=\'https://www.instagram.com/wwcodedatascience/\' style="color:#3e8ed0ff; ">Women Who Code (WWC) Data Science Track</a> for an <b style="color:#778492;">Instagram Live</b> to talk about Transitioning to Data Science.',
    links: [
      {
        url: "https://www.instagram.com/p/CfIREc8riod/?utm_source=ig_web_copy_link",
        text: "Instagram",
        icon: "fab fa-instagram",
        tagColor: "is-info",
      },
      {
        url: "https://www.instagram.com/tv/CfMi6Z1Fy1W/?utm_source=ig_web_copy_link",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "June 2022",
    ignore: false,
    year: "2022",
    activityType: "speak",
    thumbnail: WAD22,
    date: "Jun 2022",
    descV2: 1,
    organisation: "WeAreDevelopers World Congress, Berlin",
    title:
      "Speaker at <a href='https://www.wearedevelopers.com/world-congress/#/speakers' style=\"color:#3e8ed0ff; \">WeAreDevelopers World Congress 2022</a>, Berlin",
    url: "https://www.wearedevelopers.com/world-congress/#/speakers",
    image: "fas fa-chalkboard-teacher",
    desc: 'Delivered a talk titled <b style="color:#778492;">Build your Backend using FastAPI </b> at <a href=\'https://www.wearedevelopers.com/world-congress/#/speakers\' style="color:#3e8ed0ff; ">WeAreDevelopers World Congress 2022</a>, Berlin.',
    links: [
      {
        url: WADTalk,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/b2ea60d1b2fc904e4f80b069c9a391af",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://youtu.be/9oBxzace59s",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "June 2022",
    ignore: false,
    year: "2022",
    activityType: "blog",
    date: "Jun 2022",
    title: "Blog series on MLOps is now live!",
    url: "https://medium.com/@ashmi_banerjee/demystifying-mlops-propelling-models-from-prototype-to-production-960b761bb02c",
    image: "fas fa-blog",
    desc: "Blog series <a href='https://medium.com/@ashmi_banerjee/demystifying-mlops-propelling-models-from-prototype-to-production-960b761bb02c' style=\"color:#3e8ed0ff; \">Demystifying MLOps — Propelling Models from Prototype to Production</a> is now available on <a href='https://medium.com/@ashmi_banerjee' style=\"color:#3e8ed0ff; \">Medium</a>.",
    links: [
      {
        url: "https://gist.github.com/ashmibanerjee/5f3de7e3668aeddc47ef0a25ec59cb29",
        text: "Blog",
        icon: "fas fa-blog",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "June 2022",
    ignore: false,
    year: "2022",
    thumbnail: EWiT,
    //date: "Jun 2022 <i style=\"color: #778492;font-size: small; \">[upcoming]</i>",
    activityType: "speak",
    organisation: "European Women in Technology, Amsterdam",
    date: "Jun 2022",
    descV2: 1,
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://www.europeanwomenintech.com/speakers/'>European Women In Technology 2022</a>, Amsterdam ",
    url: "https://www.europeanwomenintech.com/speakers/",
    image: "fas fa-chalkboard-teacher",
    desc: '<span style="word-break: break-word;">Delivered a talk titled <b style="color:#778492;">Build your Backend using FastAPI </b> at <a href=\'https://www.europeanwomenintech.com/speakers/\' style="color:#3e8ed0ff; ">European Women in Technology 2022</a>, Amsterdam.</span>',
    links: [
      {
        url: EWTtalk,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/5f3de7e3668aeddc47ef0a25ec59cb29",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://virtual-stage.eventtia.com/en/europeanwomenintech/stage/210425",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "May 2022",
    ignore: false,
    year: "2022",
    date: "May 2022",
    activityType: "speak",
    thumbnail: WWC,
    organisation: "Connect Recharge, Women Who Code (WWC), Remote",
    descV2: 1,
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://hopin.com/events/connect-recharge-2022/registration'>Connect Recharge, Women Who Code (WWC)</a>, Remote ",
    url: "https://hopin.com/events/connect-recharge-2022/registration",
    image: "fas fa-chalkboard-teacher",
    desc: 'Delivered a talk titled <b style="color:#778492;">Build your Backend using FastAPI </b> at <a href=\'https://hopin.com/events/connect-recharge-2022/registration\' style="color:#3e8ed0ff; ">Connect Recharge, Women Who Code (WWC)</a>.',
    links: [
      {
        url: WWC2022Talk,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/74bd74e6098bb5b1ee5a754997998e11",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
      {
        url: "https://www.youtube.com/watch?v=xpWe_m-ukBk&list=PLVcEZG2JPVhcLkMbJ3TkSajGDfFNOdCEd&index=14",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "April 2022",
    ignore: false,
    year: "2022",
    date: "Apr 2022",
    descV2: 1,
    activityType: "speak",
    thumbnail: GDSC,
    organisation: "Google Developers Students Club (GDSC), ETH Zurich",
    title:
      "Speaker at <a style=\"color: #3e8ed0ff;\" href='https://www.wearedevelopers.com/world-congress/#/speakers'>Google Developer Student Club, ETH Zurich </a>chapter",
    url: "https://gdsc.community.dev/events/details/developer-student-clubs-eth-zurich-presents-voices-of-stem-women-build-your-backend-using-fastapi/",
    image: "fas fa-chalkboard-teacher",
    desc: 'Delivered a talk titled <b style="color:#778492;">Build your Backend using FastAPI </b> at <a href=\'https://gdsc.community.dev/events/details/developer-student-clubs-eth-zurich-presents-voices-of-stem-women-build-your-backend-using-fastapi/\' style="color:#3e8ed0ff; ">Google Developer Student Club, ETH Zurich</a> as a part of their Voices of STEM Women series.',
    links: [
      {
        url: GDSC22Talk,
        text: "Slides",
        icon: "fas fa-file-powerpoint",
        tagColor: "is-info",
      },
      {
        url: "https://gist.github.com/ashmibanerjee/d2815cdea6b869360f794456df7d060a",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "February 2022",
    ignore: false,
    year: "2022",
    date: "Feb 2022",
    activityType: "workshop",
    title:
      "<a style=\"color: black;  \" href='https://events.withgoogle.com/women-developer-academy-europe/'>Google Women Developer Academy, Europe</a>",
    url: "https://events.withgoogle.com/women-developer-academy-europe/",
    image: "fas fa-trophy",
    desc: 'Selected to attend <a style="color: #3e8ed0ff;  " href=\'https://events.withgoogle.com/women-developer-academy-europe/\'>Women Developer Academy,</a> a special training conducted by <b style="color:black;">Google</b>, to equip women in tech with public speaking, leadership skills, and other community contributions.',
    // links: [
    //     {
    //         url:WDACerti,
    //         text: "View Certificate",
    //         icon: "fas fa-external-link-alt",
    //         tagColor: "is-info",
    //     },
    // ]
  },
  {
    duration: "January 2022",
    ignore: false,
    year: "2022",
    date: "Jan 2022",
    activityType: "featured",
    thumbnail: Bicon,
    organisation:
      "From the Start up! trainee job interview to an entrepreneur | Deutsche Telekom Career Blog",
    title:
      "<a style=\"color: black;  \" href='https://www.telekom.com/en/blog/careers/article/start-up-trainee-and-entrepreneur-643398'>From the Start up! trainee job interview to an entrepreneur @ Deutsche Telekom </a>",

    url: "https://www.telekom.com/en/blog/careers/article/start-up-trainee-and-entrepreneur-643398",
    image: "fas fa-blog",
    desc: 'Featured on the <span style="color:#3e8ed0ff;">Deutsche Telekom</span> <a style="color: black; ; font-weight: bold" href=\'https://www.telekom.com/en/blog/careers/article/start-up-trainee-and-entrepreneur-64339\'>Career Blog</a> for intrapreneurship endeavors.',
    links: [
      {
        url: "https://www.telekom.com/en/blog/careers/article/start-up-trainee-and-entrepreneur-643398",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
      {
        url: "https://bicon.hubraum.opsfactory.dev/",
        text: "Website",
        icon: "fas fa-globe",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "October 2021",
    ignore: false,
    year: "2021",
    title: "HTML/CSS Workshop @ Deutsche Telekom",
    organisation: "Deutsche Telekom, Germany",
    url: "",
    // activityType:"speak",
    image: "fas fa-code",
    desc: 'Conducted a <b style="color:black;">workshop</b> on HTML/CSS as a part of the <b>Software Development Explorer Journey</b> at <span style="color:#3e8ed0ff;">Deutsche Telekom</span>.',
  },
  {
    duration: "September 2021",
    ignore: false,
    year: "2021",
    title: "Speaker @ Femtec Career Workshop, Deutsche Telekom",
    organisation: "Deutsche Telekom, Germany",
    // activityType:"speak",
    url: "",
    image: "fas fa-chalkboard-teacher",
    desc: 'Delivered a <b style="color:black;">talk </b> about women in STEM at Deutsche Telekom during the <span style="color:#3e8ed0ff;">Femtec Career Workshop</span>.',
  },
  {
    duration: "September 2021",
    ignore: false,
    year: "2021",
    activityType: "podcast",
    organisation: "Podcast: Never Give up at Minding Thought",
    thumbnail: MendingThought,
    title:
      "<a style=\"color: black; \" href='https://open.spotify.com/episode/1V3mtgeML9GSL2AD3AR5Pp?si=6h9M3Yo3RReUyUE4FTrTIg&utm_source=native-share-menu&nd=1'>Recorded my first podcast for Mending Thought</a>",
    url: "https://open.spotify.com/episode/1V3mtgeML9GSL2AD3AR5Pp?si=6h9M3Yo3RReUyUE4FTrTIg&utm_source=native-share-menu&nd=1",
    company: [],
    location: "",
    image: "fas fa-microphone-alt",
    desc: 'Recorded a <b style="color:black;">podcast</b> for <a style="color:#3e8ed0ff; " href=\'https://open.spotify.com/episode/1V3mtgeML9GSL2AD3AR5Pp?si=6h9M3Yo3RReUyUE4FTrTIg&utm_source=native-share-menu&nd=1\'>Mending Thought</a> hosted by Charulatha Jain.',
    links: [
      {
        url: "https://open.spotify.com/episode/1V3mtgeML9GSL2AD3AR5Pp?si=6h9M3Yo3RReUyUE4FTrTIg&utm_source=native-share-menu&nd=1",
        text: "Spotify",
        icon: "fab fa-spotify",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "July 2021",
    ignore: false,
    year: "2021",
    activityType: "speak",
    thumbnail: iWiC,
    organisation: "Anita Borg's Indian Women in Computing (IWiC)",
    title:
      "<a style=\"color: black; \" href='https://www.youtube.com/watch?v=fFsRklAywdw&t=32s'> Panelist @ Indian Women in Computing event </a>",
    url: "https://www.youtube.com/watch?v=fFsRklAywdw&t=32s",
    image: "fas fa-users",
    location: "",
    desc: '<span style="text-align: justify; text-justify: inter-word; word-wrap: break-word"><b style="color:black;"> Panelist </b> on <a style="color:black; ; font-weight: bold" href=\'https://www.youtube.com/watch?v=fFsRklAywdw&t=32s/\'>Passport to Europe with Student Visa</a> hosted by <span style="color:#3e8ed0ff;">Indian Women in Computing</span>.</span>  ',
    links: [
      {
        url: "https://www.youtube.com/watch?v=fFsRklAywdw&t=32s",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "June 2021",
    ignore: false,
    year: "2021",
    activityType: "speak",
    thumbnail: TUM,
    organisation:
      "Pitch Perfect Pitch Competition, Technical University of Munich, Germany",
    title:
      "<a style=\"color:black; \" href='https://padlet.com/TUM_EDay/pw028l6ay6zofhp5'>TUM Pitch Perfect competition</a>",
    url: "https://bimage.hubraum.opsfactory.dev/",
    urlText: "Product Webpage",
    location: "",
    image: "fas fa-trophy",
    desc: '<b style="color:black;">Finalist (top 10)</b> at the <span style="color:#3e8ed0ff;">TUM Entrepreneurship Day 2021</span> <a style="color:black; ;font-weight: bold" href=\'https://www.youtube.com/watch?v=7a0eO5s6ZX0\'>Pitch Perfect competition</a>.',
    links: [
      {
        url: "https://bicon.hubraum.opsfactory.dev/",
        text: "Website",
        icon: "fas fa-globe",
        tagColor: "is-warning",
      },
      {
        url: "https://www.youtube.com/watch?v=7a0eO5s6ZX0",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "2021",
    ignore: false,
    image: "fas fa-trophy",
    year: "2021",
    activityType: "scholarship",
    title: "Lean Innovation (former UQBATE) Scholarship",
    desc: 'Selected for the <span style="color:#3e8ed0ff;">Deutsche Telekom Incubator program (former UQBATE)</span> to realize a product idea for a low-cost bike tracking solution.',
  },
  {
    duration: "November 2018",
    ignore: false,
    image: "fas fa-chalkboard-teacher",
    year: "2018",
    activityType: "speak",
    thumbnail: MunichScienceSlam,
    organisation: "Munich Science Slam, Germany",
    title: "Talk @ Munich Science Slam",
    desc: 'Speaker at <span style="color:#3e8ed0ff;">Munich Science Slam</span> on <a style="color:black; ; font-weight: bold;" href=\'https://www.munich-science-slam.com/talks/ashmi_banerjee/\'>Automatic travel preference elicitation from social media</a>.',
    links: [
      {
        url: "https://www.munich-science-slam.com/talks/ashmi_banerjee/",
        text: "Video",
        icon: "fas fa-video",
        tagColor: "is-success",
      },
    ],
  },
  {
    duration: "November 2018",
    ignore: false,
    image: "fas fa-users",
    year: "2018",
    organisation: "Nokia, Our Greenhouse, Munich",
    activityType: "speak",
    thumbnail: Greenhouse,
    title: "Speaker @ Nokia Our Greenhouse",
    desc: 'Invited as a panelist at <span style="color:#3e8ed0ff;">Nokia Deutschland</span> to speak about under-representation of women in tech at Nokia\'s exclusive annual <a style="color:black; ; font-weight: bold;" href=\'https://www.nokia.com/blog/gender-diversity-way-go/\'>Greenhouse event</a> in Munich, Germany. Was the youngest and only student in the panel.',
    links: [
      {
        url: "https://www.nokia.com/blog/gender-diversity-way-go",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "December 2018",
    ignore: false,
    year: "2016",
    date: "2018",
    title: "IFF Gender & Diversity Grant 2018 from | Munich",
    activityType: "featured",
    organisation: "IFF Gender & Diversity Grant 2018 from TU Munich",
    url: "https://www.in.tum.de/news-single-view/article/iff-gender-diversity-grants/",
    image: "fas fa-blog",
    thumbnail: TUMIff,
    desc: "Featured on the TU Munich website for receiving a travel grant from Informatic Forum Frauen (IFF) to attend the <a style=\"color:#3e8ed0ff; \" href='https://www.in.tum.de/news-single-view/article/iff-gender-diversity-grants/'>European Women in Technology Conference</a> 2018 in Amsterdam, Netherlands.",
    icon: "fas fa-newspaper",

    links: [
      {
        url: "https://www.in.tum.de/in/news-single-view/article/iff-gender-diversity-grants/",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "August 2016",
    ignore: false,
    year: "2016",
    date: "Aug 2016",
    title: "YOU Scholarship 2016 | The Telegraph, Kolkata",
    activityType: "featured",
    organisation: "The Telegraph, Kolkata",
    url: "https://www.telegraphindia.com/education/you-scholarship/cid/1441077",
    image: "fas fa-blog",
    thumbnail: Telegraph,
    desc: 'Featured on <span style="color:#3e8ed0ff;">The Telegraph, Kolkata</span> <a style="color: black; ; font-weight: bold" href=\'https://www.telegraphindia.com/education/you-scholarship/cid/1441077\'>newspaper</a> for being a regional finalist at the YOU Scholarship for postgraduate studies.',
    icon: "fas fa-newspaper",

    links: [
      {
        url: "https://www.telegraphindia.com/education/you-scholarship/cid/1441077",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    image: "fas fa-users",
    ignore: false,
    duration: "",
    year: "",
    title: "",
    desc: "<i>.... work in progress, will add previous highlights soon!</i>.",
  },
];
