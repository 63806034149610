import React, { useState } from "react";
import "./projectTemplate.css";

function createMarkup(data) {
  return { __html: data };
}

const ProjectTemplate = ({
                           title,
                           company,
                           duration,
                           desc,
                           image,
                           multiPositions,
                           shouldEnableDropDown, // Accepting shouldEnableDropDown as a prop
                         }) => {
  const diamondSymbol = " \u25C6 ";
  const [showDesc, setShowDesc] = useState(false);

  const toggleDescription = () => {
    if (shouldEnableDropDown) { // Only toggle if shouldEnableDropDown is true
      setShowDesc((prev) => !prev);
    }
  };

  const renderFirstLine = company[0]?.name ? (
    <span>
      <a href={company[0].url}>{company[0].name}</a>
      {diamondSymbol}
    </span>
  ) : null;

  const renderMultiPositions = multiPositions ? (
    <div>
      {multiPositions.map((position, index) => (
        <article className="media" key={index}>
          <div className="content" style={{ overflow: "hidden" }}>
            <strong style={{ color: "#545e69" }}>{position.name}</strong>
            <span style={{ color: "#ea4f4b" }}>
              <a href={company[0].url}>{position.company}</a>
            </span>
            <br />
            <span style={{ color: "rgb(84, 94, 105)" }}>{position.duration}</span>
            <br />
            <p style={{ marginTop: "0.8rem", color: "#778492" }}>
              {position.desc?.map((item, idx) => (
                <span key={idx} style={{ fontSize: "small" }}>
                  {item.para}
                  <br />
                </span>
              ))}
            </p>
          </div>
        </article>
      ))}
    </div>
  ) : null;

  const renderImage = image && (
    <div
      className="image-container"
      style={{
        flexShrink: 0, // Prevents the image from shrinking
        width: "64px",
        height: "64px",
        marginTop: "1.5rem",
      }}
    >
      <img
        src={image}
        alt="Project"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );

  const renderSubheading = (
    <p className="subtitle is-6 text-adjustment">
      {duration && <span>{duration}<br /></span>}
      {company[0]?.employmentType ? (
        <p style={{ color: "black" }} className="text-adjustment">
          {renderFirstLine}
          {company[0].employmentType}
          {diamondSymbol}
          <i>{company[0].location}</i>
        </p>
      ) : (
        <p style={{ color: "black" }}>
          {renderFirstLine}
          <i>{company[0]?.location}</i>
        </p>
      )}
    </p>
  );

  const renderDescription = desc && (
    <p style={{ marginTop: "0.8rem" }}>
      {desc.map((item, index) => (
        <span
          key={index}
          className="text-adjustment"
          style={{ fontSize: "small", color: "#778492" }}
        >
          <div dangerouslySetInnerHTML={createMarkup(item.para)} />
        </span>
      ))}
    </p>
  );

  const renderToggleContent = shouldEnableDropDown && showDesc && (
    <>
      {renderMultiPositions}
      {renderDescription}
    </>
  );

  const renderTitleLink = company[0]?.url ? (
    <a href={company[0].url} className="link-reverse">
      <div dangerouslySetInnerHTML={createMarkup(title)} />
    </a>
  ) : (
    <div dangerouslySetInnerHTML={createMarkup(title)} />
  );

  // If shouldEnableDropDown is false, render description directly without toggle
  const renderContent = ! shouldEnableDropDown ? (
    <div
      className="card-content"
      style={{
        marginTop: "-2rem",
        marginLeft: "-1rem",
        marginBottom: "-3rem",
      }}
    >
      {renderDescription}
    </div>// Wrap renderDescription in card-content when dropdown is disabled
  ): null

const showDropdown = shouldEnableDropDown ? (<a
  className={`card-header-icon card-toggle ${showDesc ? "is-right" : ""}`}
  onClick={toggleDescription}
>
  <i className={`fa fa-angle-${showDesc ? "up" : "down"} fa-1x`} />
</a>) : null

  return (
    <div className="card row m-2 is-shadowless">
      <article className="media" style={{ overflow: "hidden" }}>
        {renderImage}
        <div className="media-content" style={{ overflow: "hidden" }}>
        <div className="card-content">
            <h4 className="text-adjustment">{renderTitleLink}</h4>
            {renderSubheading}
            {renderToggleContent}
            {renderContent}
          </div>
        </div>
        {showDropdown}
      </article>
    </div>
  );
};
export default ProjectTemplate;
