import * as React from "react";
import "./App.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Cv from "./pages/cv";
import Blogs from "./components/blogs/Blogs";
import Talks from "./components/talks/Talks";
import Teach from "./components/teach/Teach";
import Volunteering from "./components/miscellaneous/Volunteering";
import ScholasticAchievements from "./components/achievements/ScholasticAchievements";
import TechSkills from "./components/skills/TechSkills";
import Certifications from "./components/certifications/Certifications";
import Languages from "./components/languages/Languages";
import FeaturedComponent from "./components/featured/FeaturedComponent";
import AcademicServices from "./components/miscellaneous/AcademicServices";

const App = () => {
  return (
    <Router>
      <RoutedApp />
    </Router>
  );
};

const RoutedApp = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/cv" element={<Cv />} />

        <Route path="/#blogs" element={<Blogs />} />
        <Route path="/#talks" element={<Talks />} />
        <Route path="/#teach" element={<Teach />} />
        <Route path="/#academic-services" element={<AcademicServices />} />

        <Route path="/cv/#volunteering" element={<Volunteering />} />
        <Route path="/cv/#achievements" element={<ScholasticAchievements />} />
        <Route path="/cv/#features" element={<FeaturedComponent />} />

        <Route path="/cv/#tech-skills" element={<TechSkills />} />
        <Route path="/cv/#certifications" element={<Certifications />} />
        <Route path="/cv/#linguistic" element={<Languages />} />
      </Routes>
    </>
  );
};

export default App;
