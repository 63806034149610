import React from "react";
import "./aboutMe.css";
import "../../pages/home.scss";

const AboutMe = () => {
  return (
    <div id={"about-me"} className="content">
      <div className="about-me-text">
        <p>
          I am currently pursuing my doctoral studies at the{" "}
          <a href="https://www.ce.cit.tum.de/cm/home/">
            Chair of Connected Mobility
          </a>{" "}
          at the{" "}
          <a href="https://tum.de/">Technical University of Munich (TUM)</a>,
          Germany, where my research focuses on
          <b> Recommender Systems</b> and <b>Human-Computer Interaction</b>. I
          hold a master's degree in Computer Science from the same university.
        </p>

        <p>
          {" "}
          I am passionate about using technology to automate tedious tasks and
          enjoy exploring the intersection of technology and human-centric
          design.
          My past experiences working on data science, AI, and software
          engineering projects at various companies such as{" "}
          <a href="https://www.telekom.de/">Deutsche Telekom</a>,{" "}
          <a href="https://www.experteer.de/">Experteer</a>,{" "}
          and{" "}
          <a href="https://www.mpi-sws.org/">
            Max Planck Institute for Software Systems
          </a>{" "}
          have helped me gain a deep understanding of the field. In addition, I
          am a self-taught web developer and hold the title of{" "}
          <b>Google Developer Expert (GDE) in Machine Learning</b>.
        </p>

        <p>
          {" "}
          As a{" "}
          <a href="https://developers.google.com/womentechmakers">
            Google Women Techmakers (WTM) Ambassador
          </a>{" "}
          and a diversity advocate, I am dedicated to closing the gender gap in
          STEM through my involvement in various women in STEM networks
          like{" "}
          <a href={"https://recsys.acm.org/recsys24/women-in-recsys/"}>ACM Women
            in RecSys</a>,{" "}
          <a href="https://www.femaletechleaders.org/">
            Female Tech Leaders (FTL)
          </a>
          , <a href="https://www.womenwhocode.com/">Women Who Code
          (WWC)</a>,{" "}
          <a
            href="https://www.in.tum.de/in/news-single-view/article/iff-gender-diversity-grants/">
            Women in CS at TUM (IFF)
          </a>
          ,{" "}
          <a href="https://www.indianwic.org/">
            Anita Borg's Indian Women in Computing (IWiC)
          </a>, etc. I am also a certified <a href={""}>mentor</a> at the{" "}
          <b>Google for Startups Accelerator Program</b>.
        </p>
        <p>
          Over the past two years, I have delivered <b>over 50 tech talks across
          10+ countries</b> in three continents.
          I was
          named{" "}
          <a
            href={
              "https://www.womenwhocode.com/100-technologists-to-watch?mc_cid=4dd7b3516a&mc_eid=99ae716a8c"
            }
          >
            one of the 100 technologists to watch for 2023{" "}
          </a>
          and won the{" "}
          <a
            href={
              "https://ashmibanerjee.com/static/media/GDE_Award.d7a642f60608cdce2850.pdf"
            }
          >
            Google Developer Expert Community Award
          </a>{" "}
          <b>(Rising Star)</b>, the{" "}
          <a>2023 Women Who Code Applaud Her Award </a> <b>(Data Science)</b>,
          and the
          <a href={"https://developher.co.uk/developher-winners-2022/"}>
            {" "}
            DevelopHER Awards 2022
          </a>{" "}
          <b>(Emerging Talent)</b>.
        </p>
        <p>
          When not sitting in front of my computer, I am either travelling or
          training for triathlons.{" "}
          {/*Despite seemingly unrelated, I*/}
          {/*attribute my endurance and resilience to these experiences. */}
           ✈️ 🏊‍♀️ 🚴
          🏃‍♀️
        </p>

        <p>
          To get in touch, drop me an email at{" "}
          <span style={{ color: "#3e8ed0ff" }}>
            first_name.last_name@tum.de
          </span>
          .
        </p>
      </div>
    </div>
  );
};
export default AboutMe;
