import React from "react";
import { ProfExperienceData } from "../../data/ProfExperiencesData";
import SectionComponent from "../project-template/SectionComponent";
import { EducationData } from "../../data/EducationData";

const ProfessionalExp = () => {
  const data = ProfExperienceData.filter(
    (item) => !item.ignore
  ).map((item) => ({
    ...item,
    shouldEnableDropDown: true, // Add the new key-value pair
  }));
  const ROWS_TO_DISPLAY = 2;
  return (
    <div id={"experiences"} className="rows">
      <p style={{ fontSize: "1.8rem", fontWeight: "600", color: "#0a478a" }}>
        Professional Experience
      </p>
      <SectionComponent
        data={data}
        count={ROWS_TO_DISPLAY}
        sectionTitle="Experiences"
      />
    </div>
  );
};

export default ProfessionalExp;
