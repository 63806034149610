import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Talks from "../talks/Talks";
import Blogs from "../blogs/Blogs";
import FeaturedComponent from "../featured/FeaturedComponent";
import Volunteering from "../miscellaneous/Volunteering";
import AcademicServices from "../miscellaneous/AcademicServices";

const Activities = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const location = useLocation();
  const hash = location.hash;
  const navigate = useNavigate();

  const tabs = [
    { id: "tab1", hash: "#talks", label: "Talks", icon: "fas" +
        " fa-microphone-alt", component: <Talks /> },
    { id: "tab2", hash: "#academic-services", label: "Services", icon: "fas fa-school", component: <AcademicServices /> },
    { id: "tab3", hash: "#volunteering", label: "Volunteering", icon: "fas" +
        " fa-hands-helping", component: <Volunteering /> },
    { id: "tab4", hash: "#blogs", label: "Blogs", icon: "fas fa-pen-alt", component: <Blogs /> },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab.id);
    navigate(tab.hash);
  };

  useEffect(() => {
    const matchedTab = tabs.find(tab => tab.hash === hash);
    if (matchedTab) setActiveTab(matchedTab.id);
  }, [hash]);

  return (
    <div id="activities">
      <article className="is-light" style={{ background: "white" }}>
        <p
          className="title"
          style={{ color: "#0a478a", fontSize: "2.0rem", fontWeight: "600" }}
        >
          Activities
        </p>
        <div className="tabs is-fullwidth is-boxed">
          <ul>
            {tabs.map(tab => (
              <li
                key={tab.id}
                className={activeTab === tab.id ? "is-active" : ""}
                onClick={() => handleTabChange(tab)}
              >
                <a>
                  <span className={`icon is-small ${activeTab === tab.id ? "is-active" : ""}`}>
                    <i className={tab.icon} aria-hidden="true"></i>
                  </span>
                  <span>{tab.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        {tabs.find(tab => tab.id === activeTab)?.component || <FeaturedComponent />}
      </article>
    </div>
  );
};

export default Activities;
