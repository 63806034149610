import React from "react";
import { HighlightsData } from "../../data/HighlightsData";
import PaginateComponent from "../project-template/PaginateComponent";
import {
  ScholasticAchievementsData
} from "../../data/ScholasticAchievementsData";

const itemsPerPage = 3;
const FeaturedOld = () => {
  const data = HighlightsData
    .filter((item) => item.activityType === "featured" && !item.ignore) // Combine both filter conditions
    .map((item) => ({
      ...item,
      shouldEnableDropDown: true, // Add the new key-value pair
    }));
  return (
    <div id={"features"} style={{ paddingTop: "2.0rem" }}>
      <p
        style={{
          fontSize: "1.8rem",
          fontWeight: "600",
          paddingBottom: "2.0rem",
          color: "#545e69",
        }}
      >
        In the News
      </p>
      <PaginateComponent data={data} itemsPerPage={itemsPerPage} />
    </div>
  );
};
export default FeaturedOld;
