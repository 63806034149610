import React from "react";
import SectionComponent from "../project-template/SectionComponent";
import { ScholasticAchievementsData } from "../../data/ScholasticAchievementsData";
import { MiscellaneousData } from "../../data/MiscellaneousData";

const ScholasticAchievements = () => {
  const data = ScholasticAchievementsData.filter(
    (item) => !item.ignore
  ).map((item) => ({
    ...item,
    shouldEnableDropDown: true, // Add the new key-value pair
  }));

  const ROWS_TO_DISPLAY = 3;
  return (
    <div id={"achievements"} className="content rows ">
      <p
        style={{
          fontSize: "1.8rem",
          fontWeight: "600",
          color: "#545e69",
          paddingTop: "2.0rem",
        }}
      >
        Awards and Honours
      </p>
      <SectionComponent
        data={data}
        count={ROWS_TO_DISPLAY}
        sectionTitle="Awards"
      />
    </div>
  );
};
export default ScholasticAchievements;
