import TUM from "../assets/images/tum.jpeg";
import Heritage from "../assets/images/heritage.jpg";
import SLS from "../assets/images/sls.jpeg";

export const EducationData = [
  {
    image: TUM,
    ignore: false,
    title: "MSc. Informatics",
    duration: "2016 - 2019",
    company: [
      {
        name: "Technical University Munich",
        url: "https://tum.de/",
        location: "Munich, Germany",
      },
    ],

    desc: [
      { para: "\u2022 Grade (GPA): 1.9/ 5.0 (German Scale)" },
      {
        para: "\u2022 Masters Thesis: Analyzing Exposure Bias in" +
          " Location-based" +
          " Search (Grade: 1.3)",
      },
      { para: "\u2022 Finished with a total of 147/120 credits." },
    ],
  },
  {
    image: Heritage,
    ignore: false,
    title: "BTech. Computer Science & Engineering",
    duration: "2012 - 2016",
    company: [
      {
        name: "Heritage Institute of Technology",
        url: "https://heritage.edu/",
        location: "Kolkata, India",
      },
    ],

    desc: [
      { para: "\u2022 Grade (CGPA): 9.13/ 10.00" },
      {
        para: "\u2022 Bachelors Thesis: Developing an alternative approach" +
          " to minimize the resource allocation for Femto Base Stations.",
      },
      {
        para: "\u2022 Chair of Association for Computing Machinery (ACM)," +
          " Heritage Chapter for the academic year 2015 -16",
      },
      {
        para: "\u2022 Webmaster of Association for Computing Machinery" +
          " (ACM), Heritage Chapter for the academic year 2014 -15",
      },
      // {para: "Organized several workshops, research talks and seminars as a part of the ACM Student's Chapter."},
      // {para: "Member of the college Throw-ball and Badminton team."}
    ],
  },
  {
    image: SLS,
    ignore: false,
    title: "High School Diploma",
    duration: "2010 - 2012",
    company: [
      {
        name: "Salt Lake School",
        url: "http://www.saltlakeschool.org/",
        location: "Kolkata, India",
      },
    ],

    desc: [
      { para: "95.5% in Indian School Certificate (ISC) Examination 2012" },
      {
        para: "93.2% in Indian Certificate of Secondary Education (ICSE) 2010",
      },
    ],
  },
];
