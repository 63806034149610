import React, { useState, useEffect } from "react";
import ProfilePic2 from "../../assets/images/Ashmi-2.jpg";
import { ContactData, SocialData } from "../../data/ContactData";
import Socials from "../intro/Socials";

function createMarkup(data) {
  return { __html: data };
}

const HeroCV = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = screenSize <= 600;
  const isMediumScreen = screenSize > 600 && screenSize <= 666;

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    maxHeight: "100vh",
    padding: "1rem",
    boxSizing: "border-box",
    flexDirection: isSmallScreen || isMediumScreen ? "column" : "row",
    justifyContent: isSmallScreen || isMediumScreen ? "center" : "flex-start",
    gap: isSmallScreen ? "1rem" : "2rem", // Adjust gap for larger screens
  };

  const imgStyle = {
    width: "200px",
    height: "200px",
    marginRight: isSmallScreen || isMediumScreen ? "0" : "2rem", // Adjusted margin for medium screens
    marginTop: isSmallScreen ? "3rem" : "0",
    marginBottom: isSmallScreen ? "1rem" : "0",
  };

  const bioStyle = {
    display: "flex",
    flexDirection: "column",
    textAlign: isSmallScreen || isMediumScreen ? "center" : "left",
    flex: 1,
    alignItems: isSmallScreen || isMediumScreen ? "center" : "flex-start",
    marginTop: isSmallScreen ? "-2rem" : "-3rem",
    gap: "1rem", // Gap between name, bio, and social icons
    maxWidth: "600px", // Limit bio width on large screens
  };

  const data = ContactData;
  const socialData = SocialData;

  return (
    <div style={containerStyle}>
      <img src={ProfilePic2} alt={"person"} style={imgStyle} />
      <div style={bioStyle}>
        <p className="is-2 name">
          <div dangerouslySetInnerHTML={createMarkup(data[0].name)} />
        </p>
        <span>
          <div dangerouslySetInnerHTML={createMarkup(data[0].bio)} />
        </span>
        <div style={{ display: "flex", justifyContent: isSmallScreen || isMediumScreen ? "center" : "flex-start", gap: "1rem" }}>
          <Socials items={socialData} iconSize={"1x"} />
        </div>
      </div>
    </div>
  );
};

export default HeroCV;
