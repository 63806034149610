import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScholasticAchievements from "../achievements/ScholasticAchievements";
import FeaturedComponent from "../featured/FeaturedComponent";

const Miscellaneous = () => {
  const location = useLocation();
  const hash = location.hash;
  const navigate = useNavigate();

  // Define the tab configurations
  const tabs = [
    {
      id: "tab1",
      label: "Awards & Honours",
      icon: "fas fa-trophy",
      hash: "#awards",
      component: ScholasticAchievements,
    },
    {
      id: "tab3",
      label: "Features",
      icon: "fas fa-photo-video",
      hash: "#features",
      component: FeaturedComponent,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab.id);
    navigate(`/${tab.hash}`);
  };

  useEffect(() => {
    const matchedTab = tabs.find((tab) => tab.hash === hash);
    if (matchedTab) {
      setActiveTab(matchedTab.id);
    }
  }, [hash]);

  // Find the active tab's component
  const ActiveComponent = tabs.find((tab) => tab.id === activeTab)?.component;

  return (
    <div id="miscellaneous">
      <article className="is-light" style={{ background: "white" }}>
        <p className="title" style={{ color: "#0a478a", fontSize: "1.8rem" }}>
          Miscellaneous
        </p>
        <div className="tabs is-fullwidth is-boxed">
          <ul>
            {tabs.map((tab) => (
              <li
                key={tab.id}
                className={activeTab === tab.id ? "is-active" : ""}
                onClick={() => handleTabChange(tab)}
              >
                <a>
                  <span
                    className={
                      "icon is-small " + (activeTab === tab.id ? "is-active" : "")
                    }
                  >
                    <i className={tab.icon} aria-hidden="true"></i>
                  </span>
                  <span>{tab.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>{ActiveComponent && <ActiveComponent />}</div>
      </article>
    </div>
  );
};

export default Miscellaneous;
