import React, { useState } from "react";
import ProjectTemplate from "../project-template/ProjectTemplate";
import "./projectTemplate.css";

const SectionComponent = (props) => {
  const [itemsToShow, setItemsToShow] = useState(props.count);

  const showMore = () => {
    setItemsToShow(props.data.length);
  };

  const showLess = () => {
    setItemsToShow(props.count);
  };

  // Only show the "Show More" button if the data length is greater than the count
  const shouldShowMoreButton = props.data.length > props.count;

  console.log("here: ", props.data)
  return (
    <div>
      {props.data.slice(0, itemsToShow).map((item, index) => (
        <ProjectTemplate
          key={index}
          image={item.image}
          title={item.title}
          duration={item.duration}
          company={item.company}
          desc={item.desc}
          multiPositions={item.multiPositions}
          certificate={item.certificate}
          shouldEnableDropDown={item.shouldEnableDropDown}
        />
      ))}

      {shouldShowMoreButton && itemsToShow === props.count ? (
        <button
          className="button is-primary is-light is-centered is-fullwidth show-more-button is-small"
          onClick={showMore}
          type="submit"
        >
          Show all {props.data.length} {props.sectionTitle}
        </button>
      ) : shouldShowMoreButton ? (
        <button
          className="button is-light is-centered is-fullwidth is-small"
          onClick={showLess}
        >
          Show Less
        </button>
      ) : null}
    </div>
  );
};

export default SectionComponent;
