import React from "react";
import SectionComponent from "../project-template/SectionComponent";
import { MiscellaneousData } from "../../data/MiscellaneousData";

const AcademicServices = () => {
  const data = MiscellaneousData.filter(
    (item) => !item.ignore && item.category === "academic"
  ).map((item) => ({
    ...item,
    shouldEnableDropDown: !["teaching", "reviewing"].includes(item.title.toLowerCase()), // Add the new key-value pair
  }));

  // Define the condition that determines if the dropdown should be enabled or not
  const ROWS_TO_DISPLAY = 4;
  return (
    <div id={"academic-services"} className="content rows">
      <p
        style={{
          fontSize: "1.8rem",
          fontWeight: "600",
          color: "#545e69",
          paddingTop: "2.0rem",
        }}
      >
      Academic Services
      </p>
      <SectionComponent
        data={data}
        count={ROWS_TO_DISPLAY}
        sectionTitle="Academic Services"
      />
    </div>
  );
};
export default AcademicServices;
